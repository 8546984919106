// import { gql } from 'graphql-tag'
import { Component } from 'react'
import './screen.css'

class Screen extends Component {
  constructor() {
    super()
    this.timer = null
    this.screen = null
    this.setTimer = null
    this.duration = null
    this.vMax = 50
    this.vMin = 20
    this.rate = 1000
    this.vx = Math.random() * 2 + 1
    this.vy = Math.random() * 2 + 1
    this.random = []
    this.state = {
      data: [],
      day: null,
      hours: null,
      inside: false,
      mins: null,
      month: null,
      random: null,
      sec: null,
    }
  }

  componentDidMount() {
    this.getDate()
    this.getData()
  }

  componentWillUnmount() {}

  getDate = () => {
    window.clearTimeout(this.timer)
    const now = new Date()
    const day = now.getDate()
    const month = now.getMonth() + 1
    const hours = now.getHours()
    const mins = now.getMinutes()
    const secs = now.getSeconds()

    this.setState({ day, hours, mins, month, secs }, () => {
      this.timer = setTimeout(this.getDate, 1000)
    })
  }

  initItem = e => {
    const el = e.currentTarget
    this.setState({ inside: false }, () => {
      const rx = [0 - el.offsetWidth, this.props.win[0]]
      const ry = [0 - el.offsetHeight, this.props.win[1]]
      const width = el.offsetWidth
      const height = el.offsetHeight
      const randX = this.randOff(width, this.props.win[0], rx)
      const randY = this.randOff(height * 1.5, this.props.win[1], ry)
      el.style.left = `${randX}px`
      el.style.top = `${randY}px`
      if (randX <= 0) this.vx = this.vMax
      if (randX >= this.props.win[0]) this.vx = -this.vMax
      if (randY <= 0) this.vy = this.vMax
      if (randY >= this.props.win[1]) this.vy = -this.vMax
      this.moveIn()
    })
  }

  randOff = (size, max, exclude) => {
    // const excluded_range = exclude[1] - exclude[0]
    let rand = Math.random() * (max + size)
    if (rand > exclude[0] - size && rand < exclude[0] + max / 2) {
      // const extra = max - rand
      rand = 0 - size
    } else if (rand > exclude[0] - size && rand > exclude[0] + max / 2) {
      const extra = max - rand
      rand += extra
    }
    return rand
  }

  moveIn = () => {
    window.clearTimeout(this.screen)
    const el = document.getElementsByClassName('screenItem')[0]
    if (el) {
      const tgtRect = el.getBoundingClientRect()
      el.style.left = `${tgtRect.left + this.vx}px`
      el.style.top = `${tgtRect.top + this.vy}px`
      if (
        tgtRect.top > 0 &&
        tgtRect.left > 0 &&
        tgtRect.bottom < this.props.win[1] &&
        tgtRect.right < this.props.win[0]
      ) {
        this.setState({ inside: true }, () => {
          this.screen = setTimeout(this.moveItem, this.rate)
          this.duration = setTimeout(this.moveOut, 40000)
        })
      } else {
        this.screen = setTimeout(this.moveIn, this.rate)
      }
    }
  }

  moveOut = () => {
    this.setState({ inside: false }, () => {
      window.clearTimeout(this.screen)
      window.clearTimeout(this.duration)
      const el = document.getElementsByClassName('screenItem')[0]
      if (el) {
        let xOut = false
        let yOut = false
        const tgtRect = el.getBoundingClientRect()
        el.style.left = `${tgtRect.left + this.vx}px`
        el.style.top = `${tgtRect.top + this.vy}px`
        if (
          tgtRect.left < 0 - tgtRect.width ||
          tgtRect.left > this.props.win[0]
        )
          xOut = true
        if (tgtRect.top < 0 - tgtRect.height || tgtRect.top > this.props.win[1])
          yOut = true
        if (xOut || yOut) {
          this.getRandom()
        } else {
          this.screen = setTimeout(this.moveOut, this.rate)
        }
      }
    })
  }

  newScreen = () => {}

  moveItem = () => {
    window.clearTimeout(this.screen)
    const el = document.getElementsByClassName('screenItem')[0]
    if (el) {
      const tgtRect = el.getBoundingClientRect()
      if (tgtRect.left < 0) {
        this.vx = Math.random() * this.vMax + this.vMin
      }
      if (tgtRect.top < 0) {
        this.vy = Math.random() * this.vMax + this.vMin
      }
      if (tgtRect.right > this.props.win[0]) {
        this.vx = (Math.random() * this.vMax + this.vMin) * -1
      }
      if (tgtRect.bottom > this.props.win[1]) {
        this.vy = (Math.random() * this.vMax + this.vMin) * -1
      }
      el.style.left = `${tgtRect.left + this.vx}px`
      el.style.top = `${tgtRect.top + this.vy}px`
      this.screen = setTimeout(this.moveItem, this.rate)
    }
  }

  getData = () => {
    fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            options(filter: { rootpage: "screen" }) {
              title
              media(filter: { rootPage: "screen" }) {
                url
                name
                type
              }
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then(res => res.json())
      .then(data =>
        this.setState(
          {
            data: data.data.options,
          },
          () => {
            this.getRandom()
          }
        )
      )
  }

  getRandom = () => {
    window.clearTimeout(this.setTimer)
    const curr = Math.ceil(Math.random() * this.state.data.length)
    let newRand = true
    for (let i = 0; i < this.random.length; i++) {
      if (curr === this.random[i]) newRand = false
      if (i === this.state.data.length - 1) {
        this.random = []
      }
    }
    if (newRand === false) {
      this.getRandom()
    } else {
      this.setState({ random: curr }, () => {
        this.random.push(curr)
      })
    }
  }

  playVid = e => {
    e.currentTarget.firstChild.play()
  }

  setRandom = e => {
    this.setState({ random: null })
    e.currentTarget.parentNode.classList.remove('fadeOn')
    e.currentTarget.parentNode.classList.add('hide')
    this.setTimer = setTimeout(this.getRandom, 5000)
  }

  render() {
    const { win } = this.props
    const { month, day, hours, mins, secs, data, random, inside } = this.state
    return (
      <>
        <div
          className='screenHold bgBlack fadeOn'
          style={{ height: win[1], width: win[0] }}
        >
          {data.length && random ? (
            data[random - 1].media.length ? (
              data[random - 1].media[0].type === 'images' ? (
                <div
                  className='screenItem fadeOn'
                  onAnimationStart={this.initItem}
                  key={`screen-${data}-${random}`}
                >
                  <img
                    className='imgFull'
                    src={this.props.getImg(
                      data[random - 1].media[0].name,
                      1400
                    )}
                  ></img>
                </div>
              ) : (
                <div
                  className='screenVideo centeredContent fadeOn'
                  onAnimationEnd={this.playVid}
                  key={`screen-${data}-${random}`}
                >
                  <video
                    className='imgContain '
                    autoPlay
                    playsInline
                    preload='auto'
                    onEnded={this.setRandom}
                  >
                    <source src={data[random - 1].media[0].url}></source>
                  </video>
                </div>
              )
            ) : (
              ''
            )
          ) : (
            ''
          )}

          <div className='fullWidth plMed prMed clockHold centeredContent eNone blinkFast'>
            <div className='topClock flex mbHuge'>
              <div
                className='tCenter tWhite colThird fadeOn'
                style={{ animationDelay: '1.5s' }}
              >
                <p className='tClock onNorm tWhite'>{`${
                  month < 10 ? '0' : ''
                }${month}`}</p>
                <p className='tSmP tUpper tWhite'>Month</p>
              </div>
              <div className='colThird relative'>
                {data.length && random && inside ? (
                  <p className='onNorm tCenter tFooter tWhite centeredContent fullWidth'>
                    {this.props.typeOn(data[random - 1].title, 3)}
                  </p>
                ) : (
                  ''
                )}
              </div>
              <div
                className='tCenter tWhite colThird fadeOn'
                style={{ animationDelay: '1.7s' }}
              >
                <p className='tClock onNorm tWhite'>{`${
                  day < 10 ? '0' : ''
                }${day}`}</p>
                <p className='tSmP tUpper tWhite'>Day</p>
              </div>
            </div>
            <div className='botClock flex'>
              <div
                className='tCenter tWhite colThird fadeOn'
                style={{ animationDelay: '1.9s' }}
              >
                <p className='tClock onNorm tWhite'>{`${
                  hours < 10 ? '0' : ''
                }${hours}`}</p>
                <p className='tSmP tUpper tWhite'>Hours</p>
              </div>
              <div
                className='tCenter tWhite colThird fadeOn'
                style={{ animationDelay: '2.1s' }}
              >
                <p className='tClock onNorm tWhite'>{`${
                  mins < 10 ? '0' : ''
                }${mins}`}</p>
                <p className='tSmP tUpper tWhite'>Minutes</p>
              </div>
              <div
                className='tCenter tWhite colThird fadeOn'
                style={{ animationDelay: '2.3s' }}
              >
                <p className='tClock onNorm tWhite'>{`${
                  secs < 10 ? '0' : ''
                }${secs}`}</p>
                <p className='tSmP tUpper tWhite'>Seconds</p>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Screen
