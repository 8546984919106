import i18next from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { createRoot } from 'react-dom/client'
import { initReactI18next } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import 'windi.css'

import App from './App'
import './index.css'

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    cleanCode: true,
    fallbackLng: 'en',
    supportedLngs: ['ja', 'en'],
  })

// const tintStyle: React.HTMLAttributes<HTMLDivElement>['style'] = {
//   inset: -120,
//   pointerEvents: 'none',
//   position: 'fixed',
//   zIndex: 1000000,
// }

createRoot(document.getElementById('root')!).render(
  <BrowserRouter>
    {/* <div
      aria-hidden
      style={{
        ...tintStyle,
        backgroundColor: '#64F0DF',
        mixBlendMode: 'darken',
      }}
    />
    <div
      aria-hidden
      style={{
        ...tintStyle,
        backgroundColor: '#64F08B',
        mixBlendMode: 'multiply',
        opacity: 0.25,
      }}
    /> */}
    <App />
  </BrowserRouter>
)
