import { Component } from 'react'

// import { Link, Route, Redirect } from 'react-router-dom'

class Map extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
    }
  }

  componentDidMount() {}

  componentWillUnmount() {}

  render() {
    const { win, origin, subOrigin, mScale, map } = this.props
    return (
      <>
        {map > 0 ? (
          <div className='viewport bWhite bSm relative'>
            <div
              className='viewScale bWhite bSm'
              style={{
                height: 'auto',
                transform: `translate3d(${
                  map === 1
                    ? Math.abs(origin[0]) *
                      ((this.props.mobile ? 100 : 120) /
                        (win[0] * mScale[map - 1]))
                    : Math.abs(subOrigin[0]) *
                      ((this.props.mobile ? 100 : 120) /
                        (win[0] * mScale[map - 1]))
                }px,${
                  map === 1
                    ? Math.abs(origin[1]) *
                      ((this.props.mobile ? 100 : 120) /
                        (win[0] * mScale[map - 1]))
                    : Math.abs(subOrigin[1]) *
                      ((this.props.mobile ? 100 : 120) /
                        (win[0] * mScale[map - 1]))
                }px,0)`,
                width: `${100 / mScale[map - 1]}%`,
              }}
            >
              <div
                className='viewBuff fullWidth'
                style={{
                  paddingBottom: `${Math.floor((win[1] / win[0]) * 100)}%`,
                }}
              ></div>
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    )
  }
}

export default Map
