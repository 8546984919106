// import { gql } from 'graphql-tag'
import { Component, Fragment } from 'react'
import { isMobile } from 'react-device-detect'

// import { Route } from 'react-router-dom'

import Hub from './hub.js'
import './landing.css'

class Landing extends Component {
  constructor() {
    super()
    this.iCount = 0
    this.images = []
    this.ran = []
    this.loaded = []
    this.state = {
      about: [],
      data: [],
      finish: [],
      image: 0,
      images: [],
      img: 0,
      intro: [],
      loaded: 0,
      opt: 0,
      ran: 0,
      zoom: [],
    }
  }

  componentDidMount() {
    //  this.props.animCount(true)
    this.props.initMap(4, 0)
    this.props.secChange('')
    this.getData()
  }

  componentWillUnmount() {
    this.props.toolReset()
  }

  getData = () => {
    fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            medias(filter: { type: "images" }) {
              url
              name
              rootPage
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then(res => res.json())
      .then(data =>
        this.setState(
          {
            data: data.data.medias,
          },
          () => {
            this.getOptions()
            if (this.props.count >= 1) {
              this.loadDone()
              this.props.animCount(true)
            } else {
              this.countImages()
            }
          }
        )
      )
  }

  getOptions = () => {
    fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            options(filter: { type: "zoom", rootpage: "landing" }) {
              title
              opt1
              id
              media(filter: { section: "zoom", rootPage: "landing" }) {
                url
                name
              }
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then(res => res.json())
      .then(data =>
        this.setState(
          {
            zoom: data.data.options,
          },
          () => {
            this.getVideo()
          }
        )
      )
  }

  getVideo = () => {
    fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            medias(filter: { rootPage: "finish", section: "finish" }) {
              url
              name
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then(res => res.json())
      .then(data =>
        this.setState({
          finish: data.data.medias,
        })
      )
  }

  countImages = () => {
    const pro = [...this.state.data]
    pro.forEach(({ rootPage, name }) => {
      this.iCount++
      if (rootPage !== 'quill' && rootPage !== 'landing') this.ran.push(name)
      this.images.push(name)
    })
    this.preloadImg()
  }

  preloadImg = () => {
    const interval = 10
    this.images.forEach((name, i) => {
      setTimeout(() => {
        const img = new Image()
        img.src = this.props.getImg(name, 1200)
        this.setState({
          loaded: Math.round(((i + 1) / this.images.length) * 100),
        })
      }, i * interval)
    })
    this.setState({ images: this.ran }, () => {
      this.loadDone()
    })
  }

  loadDone = () => {
    this.setState({ loaded: 100 }, () => {
      // this.props.initUI();
    })
  }

  animCount = () => {
    const curr = this.state.opt + 1
    if (curr === 1 && !isMobile)
      document.addEventListener('mousemove', this.startUI, { passive: false })
    else if (curr === 2 && isMobile) this.props.initUI()
    // if (curr === this.state.zoom.length) this.props.animCount()
    // else if (this.state.opt !== curr) this.setState({ opt: curr })
  }

  imgCount = () => {
    const curr = this.state.img + 1
    if (curr === this.state.zoom[this.state.opt].media.length) {
      this.animCount()
    } else {
      this.setState({ img: curr })
    }
  }

  ranCount = () => {
    const curr = this.state.ran + 1
    if (curr === this.state.images.length) {
      this.animCount()
    } else {
      this.setState({ ran: curr })
    }
  }

  addCount = () => {
    let curr = this.state.count
    this.setState({ count: curr++ })
  }

  startUI = () => {
    this.props.initUI()
    document.removeEventListener('mousemove', this.startUI)
  }

  render() {
    const { win, count, info } = this.props
    const { loaded, zoom, finish, opt, img, /* data, */ ran, images } =
      this.state
    return (
      <>
        <div
          className='fullWidth fullHeight eCenter relative'
          style={{ height: win[1], width: win[0] }}
        >
          <div className='leftCorner tWhite'>
            <p className='tUpper tFooter onNorm'>
              {this.props.typeOn(`Initiating Connection`, 0)}
            </p>
            <p className='tUpper tFooter onNorm' key={`${count}-landing`}>
              {this.state.loaded}%{' '}
              {count >= 1
                ? count === 3
                  ? this.props.typeOn(`resources loaded`, 0)
                  : this.props.typeOn(`generating resources`, 0)
                : ''}
            </p>
          </div>

          {loaded === 100 ? (
            <div className='leftBottom tWhite fullWidth pbSm'>
              <div className='fullStage eCenter  flex flexStart colGut'>
                <div className='col5'>
                  <p className='tUpper tFooter onNorm'>
                    {this.props.typeOn(`Connection Established`, 0)}
                  </p>
                  <p className='tUpper tFooter onNorm trigger'>
                    {this.props.typeOn(
                      `${this.props.info.city}, ${this.props.info.state}`,
                      0.3
                    )}
                  </p>
                </div>

                <div className='col5'>
                  <p className='tUpper tFooter onNorm'>
                    {this.props.typeOn(
                      `DIGITAL ARCHIVAL PROTOCOL "Z"`,
                      0.45,
                      true
                    )}
                  </p>
                  <p className='tUpper tFooter onNorm trigger'>
                    {this.props.typeOn(`COMPILED BY ROBOT ORDINANCE 562`, 0.75)}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {count === 1 ? (
            <Fragment key={`landingOption-${opt}-${img}`}>
              {zoom[opt].title === 'text' ? (
                <h1
                  className='enterLeaveSlow tUpper introItem tWhite tCenter'
                  onAnimationEnd={this.animCount}
                >
                  {zoom[opt].opt1}
                </h1>
              ) : zoom[opt].media.length ? (
                <div
                  className='enterLeaveFastImg introItem fullWidth fullHeight'
                  onAnimationEnd={this.imgCount}
                >
                  <img
                    className='imgContain'
                    src={this.props.getImg(zoom[opt].media[img].name, 1200)}
                  ></img>
                </div>
              ) : (
                this.animCount()
              )}
            </Fragment>
          ) : (
            ''
          )}
          {count === 2 ? (
            <Fragment key={`landingOption-${ran}`}>
              {images.length ? (
                <div
                  className='enterLeaveFastImg introItem fullWidth fullHeight'
                  onAnimationEnd={this.ranCount}
                >
                  <img
                    className='imgContain'
                    src={this.props.getImg(
                      images[Math.round(Math.random() * (images.length - 1))],
                      1200
                    )}
                  ></img>
                </div>
              ) : (
                this.animCount()
              )}
            </Fragment>
          ) : (
            ''
          )}
          {count >= 3 ? (
            <>
              <div className='finishHold fullWidth fullHeight'>
                <h3
                  className='centeredContent landingTitle tCenter tWhite tUpper slowFadeIn pbHuge eNone'
                  style={{ animationDelay: '1s' }}
                >
                  zetto.plus
                </h3>
                <p
                  className='infoBio tWhite tUpper onNorm tCenter tSmP eNone'
                  style={{ animationDelay: '1s' }}
                >
                  {this.props.typeOn(info.about, 1.25)}
                </p>

                {finish.length ? (
                  <>
                    {/* <Route */}
                    {/* path='/' */}
                    {/* render={() => ( */}
                    <Hub
                      dragOn={this.props.dragOn}
                      dragOff={this.props.dragOff}
                      menu={this.props.menu}
                      subMenu={this.props.subMenu}
                      filterKey={this.props.filterKey}
                      info={this.props.info}
                      typeOn={this.props.typeOn}
                      heightOff={this.props.heightOff}
                      getImg={this.props.getImg}
                      win={this.props.win}
                      toolReset={this.props.toolReset}
                      toolInit={this.props.toolInit}
                      toolOff={this.props.toolOff}
                    />
                    {/* )} */}
                    {/* /> */}

                    <video
                      className='imgCover slowFadeIn'
                      style={{ animationDelay: '3s' }}
                      autoPlay
                      loop
                      playsInline
                      muted
                      preload='auto'
                    >
                      <source src={finish[0].url}></source>
                    </video>
                  </>
                ) : (
                  ''
                )}
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </>
    )
  }
}

export default Landing
