// import { gql } from 'graphql-tag'
import { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import ThoughtsSingle from './thoughtsSingle.js'
import './thoughts.css'

class Thoughts extends Component {
  constructor() {
    super()
    this.state = {
      data: [],
      subMenu: window.location.pathname.endsWith('/thoughts'),
    }
  }

  componentDidMount() {
    this.getData()
    this.props.secChange('thoughts')
  }

  componentWillUnmount() {
    this.props.toolReset()
    this.props.chaptersInit([])
  }

  getData = () => {
    fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            thoughts {
              id
              title
              titleJa
              type
              opt2
              opt3
              media(filter: { rootPage: "thoughts", section: "cover" }) {
                name
                url
              }
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then(res => res.json())
      .then(data =>
        this.setState(
          {
            data: data.data.thoughts,
          },
          () => {
            this.setChapters()
          }
        )
      )
  }

  prettyDate = curr => {
    const now = new Date(parseInt(curr))
    let day = now.getDate()
    let month = now.getMonth()
    if (day < 10) {
      day = `0${day}`
    }
    if (month + 1 < 10) {
      month = `0${month + 1}`
    } else {
      month = month + 1
    }

    return `${now.getFullYear()}${month}${day}`
  }

  setChapters = () => {
    const full = []
    const secs = [...this.state.data]
    for (let i = 0; i < secs.length; i++) {
      const data = {}
      // data.title = this.prettyDate(secs[i].created_at)
      data.title =
        secs[i][this.props.i18n.language === 'ja' ? 'titleJa' : 'title']
      data.workSlug = 'thoughts'
      data.work = 'Thoughts'
      data.slug = secs[i].opt3
      data.on = `RUN-THT${i < 10 ? '0' : ''}${i}: ${secs[i].opt3}`
      data.menuOn = 'Expand Entry logs'
      data.menuOff = 'Collapse Entry logs'
      full.push(data)
    }
    this.props.chaptersInit(
      full,
      window.location.pathname.endsWith('/thoughts')
    )
  }

  render() {
    const { win /* , subMenu */ } = this.props
    return (
      <div className='thoughtsHold' style={{ width: win[0] }}>
        <Routes>
          <Route
            path=':slug'
            element={
              <ThoughtsSingle
                key={this.props.chapter}
                dragOn={this.props.dragOn}
                dragOff={this.props.dragOff}
                popReset={this.props.popReset}
                popInit={this.props.popInit}
                prettyDate={this.prettyDate}
                secChange={this.props.secChange}
                getImg={this.props.getImg}
                typeOn={this.props.typeOn}
                toolInit={this.props.toolInit}
                toolOff={this.props.toolOff}
                origin={this.props.origin}
                win={win}
                filterKey={this.props.filterKey}
                chapter={this.props.chapter}
                chapterChange={this.props.chapterChange}
                toolReset={this.props.toolReset}
                heightOn={this.props.heightOn}
                heightOff={this.props.heightOff}
              />
            }
          />
        </Routes>
      </div>
    )
  }
}

export default withTranslation()(Thoughts)
