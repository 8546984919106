// import { gql } from 'graphql-tag'
import { Component, Fragment } from 'react'
import { isMobile } from 'react-device-detect'
import { Link, Navigate } from 'react-router-dom'

class Inspiration extends Component {
  constructor() {
    super()
    this.mapKeys = {}
    this.state = {
      curr: 0,
      data: null,
      depth: 2,
      pause: false,
      pos: 0,
      redirect: false,
    }
  }

  componentDidMount() {
    this.props.projectOn()
    const url = window.location.href
    const curr = url.split('/')[4]
    this.getData(curr)
    this.props.coverOn()
    window.addEventListener('keydown', this.keyInit, false)
  }

  // componentWillUpdate(){
  //   var url = window.location.href
  //   var curr = url.split('/')[4];
  //   if(this.props.chapter !== curr){
  //     this.getData(curr);
  //      this.props.chapterChange(curr);
  //     this.props.coverOn();
  //   }
  // }

  componentWillUnmount() {
    this.props.projectOff()
    this.props.subOff(true)
    this.props.subReset(0)
    this.props.coverOff()
    this.props.toolReset()
    window.removeEventListener('keydown', this.keyInit, false)
  }

  getData = curr => {
    fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            inspiration(filter: { opt2: "${curr}" }) {
              id
              title
              opt1
              opt2
              opt3
              options(filter: { rootpage: "inspiration" }) {
                id
                opt1
                opt2
                opt3
                opt4
                opt5
                rootpage
                type
                media(filter: { rootPage: "option", section: "content" }) {
                  name
                  url
                }
              }
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then(res => res.json())
      .then(data =>
        this.setState(
          {
            curr: 0,
            data: data.data.inspiration,
            pause: false,
            pos: 0,
          },
          () => {
            this.props.subReset()
            this.props.subInit(-1)
          }
        )
      )
  }

  changeVid = e => {
    const el = e.currentTarget.getAttribute('data-id')
    const pos = e.currentTarget.parentNode.getAttribute('data-pos')
    const level = e.currentTarget.parentNode.getAttribute('data-level')
    // const box = e.currentTarget.parentNode
    const w = e.currentTarget.parentNode.getBoundingClientRect().width
    const h = e.currentTarget.parentNode.getBoundingClientRect().height
    const y = e.currentTarget.parentNode.offsetTop
    const x = e.currentTarget.parentNode.offsetLeft
    this.props.mapPause()
    this.props.mapPos(-x, -y, w / 2, h / 2, level)
    this.setState({ curr: parseInt(el), pause: true, pos: parseInt(pos) })
  }

  keyInit = e => {
    if (e.key === 'Escape') this.setState({ redirect: true })
  }

  render() {
    const { data, redirect, pause } = this.state
    const { win, cover /* , menu, subMenu */ } = this.props
    this.count = -1

    return (
      <>
        {data ? (
          <div
            className='inspireStage noTouch'
            style={{
              height: win[1],
              width: win[0],
            }}
          >
            {redirect ? <Navigate to={`/inspirations`} /> : ''}
            <div
              key={`insp-${data.id}`}
              className={`secDescription fullFixed tWhite ptLrg ${
                pause ? 'pause' : ''
              }`}
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: cover ? win[1] : 'auto',
                width: win[0],
              }}
            >
              <Link
                to='/inspirations'
                className='eFill inspiroBack plHuge eFill ptSm pbSm toggleTypeOn'
                data-tool={`Back to Stream`}
                onMouseEnter={isMobile ? () => false : this.props.toolInit}
                onMouseOut={isMobile ? () => false : this.props.toolOff}
              >
                <div className='eNone'>
                  <p className='onNorm tWhite eNone tFooter inspiroPath'>
                    {this.props.typeOn(`Inspirations`, 0)}
                  </p>
                </div>
              </Link>
              <div
                className='fullWidth desHold dotHold relative eFill coverBut'
                data-status='true'
                data-tool={`show Info`}
                data-off={'hide info'}
              >
                <div
                  className={`dot blinkOn dotMid eFill ${
                    cover ? 'active' : ''
                  }`}
                ></div>
                <h1 className='tUpper mbXs col8'>{data.title}</h1>
              </div>
              <div
                className={`colGut fadeOn proDescription tPBig fullStage eCenter ptSm pbLrg ${
                  cover ? '' : 'eHide'
                }`}
                style={{
                  WebkitOverflowScrolling: 'touch',
                  flex: '1 1 auto',
                  overflowScrolling: 'touch',
                  overflowY: 'auto',
                }}
              >
                {!!data.opt1 && (
                  <div dangerouslySetInnerHTML={{ __html: data.opt1 }} />
                )}
                <div className='columns'>
                  {data.options
                    ?.filter?.(x => x.rootpage === 'inspiration')
                    .map((item, i, arr) => {
                      this.count++
                      return (
                        <Fragment key={`item-${i}`}>
                          {this.count > this.state.depth
                            ? (this.count = 0)
                            : ''}
                          {item.type === 'youtube' ? (
                            <div
                              className={`blinkOn single-${i}`}
                              data-pos={i}
                              data-level='2'
                              data-id={item.id}
                              data-tool={item.opt1}
                            >
                              <div
                                style={{
                                  paddingBottom: '56.25%',
                                  position: 'relative',
                                }}
                              >
                                <iframe
                                  src={`https://www.youtube.com/embed/${
                                    item.opt2
                                  }?autoplay=${
                                    arr.findIndex(x => x.type === 'youtube') ===
                                    i
                                      ? 1
                                      : 0
                                  }`}
                                  frameBorder='0'
                                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                  allowFullScreen
                                  style={{
                                    height: '100%',
                                    left: '0',
                                    position: 'absolute',
                                    top: '0',
                                    width: '100%',
                                  }}
                                ></iframe>
                              </div>
                            </div>
                          ) : (
                            <div
                              className={`single-${i} blinkOn`}
                              data-pos={i}
                              data-level='2'
                              data-id={item.id}
                              data-tool={item.opt1}
                            >
                              {isMobile && (
                                <div className='contentTool tWhite'>
                                  <p
                                    className='onNorm tFooter'
                                    dangerouslySetInnerHTML={{
                                      __html: item.opt1,
                                    }}
                                  ></p>
                                </div>
                              )}
                              {!!item.media.length && (
                                <img
                                  className='imgFull'
                                  src={this.props.getImg(
                                    item.media[0].name,
                                    1000
                                  )}
                                  data-id={item.id}
                                  onClick={this.changeVid.bind(this)}
                                ></img>
                              )}
                            </div>
                          )}
                        </Fragment>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </>
    )
  }
}

export default Inspiration
