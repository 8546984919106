// import { gql } from 'graphql-tag'
import { Component } from 'react'
import { Link, Route, Routes } from 'react-router-dom'

import Inspiration from './inspiration.js'
import './inspirations.css'

class Inspirations extends Component {
  constructor() {
    super()
    this.fps = 100
    this.speedFactor = 0.001
    this.minDelta = 0.5
    this.autoScrollTimer = undefined
    this.isScrolling = false
    this.prevPos = 0
    this.currentPos = 0
    this.currentTime = null
    this.prevTime = undefined
    this.timeDiff = undefined
    this.state = {
      data: [],
      loop: false,
      project: false,
      scrollSpeed: 80,
      temp: [],
    }
  }

  componentDidMount() {
    this.props.initMap(4, 0)
    this.getData()
    window.scrollTo(0, 0)
    this.props.heightOn()
    this.props.secChange('inspirations')
    window.addEventListener('scroll', this.scrollCheck, { passive: false })
    window.addEventListener('wheel', this.handleManualScroll, {
      passive: false,
    })
    window.addEventListener('touchstart', this.handleManualScroll, {
      passive: false,
    })
    window.addEventListener('touchmove', this.handleManualScroll, {
      passive: false,
    })
    window.addEventListener('touchend', this.handleManualScroll, {
      passive: false,
    })
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollCheck, { passive: false })
    window.removeEventListener('wheel', this.handleManualScroll, {
      passive: false,
    })
    window.removeEventListener('touchstart', this.handleManualScroll, {
      passive: false,
    })
    window.removeEventListener('touchmove', this.handleManualScroll, {
      passive: false,
    })
    window.removeEventListener('touchend', this.handleManualScroll, {
      passive: false,
    })
    clearInterval(this.autoScrollTimer)
    this.props.heightOff()
    this.props.toolReset()
  }

  scrollCheck = () => {
    this.currentPos = window.scrollY || window.pageYOffset
    if (this.currentPos > document.body.offsetHeight - this.props.win[1] * 1.5)
      this.runLoop()
  }

  getData = () => {
    fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            inspirations {
              id
              title
              type
              opt2
              media(filter: { rootPage: "inspiration", section: "cover" }) {
                name
                rootPage
                section
                url
              }
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then(res => res.json())
      .then(data =>
        this.setState(
          {
            data: data.data.inspirations.map(x => ({
              ...x,
              media: x.media.filter(
                x => x.rootPage === 'inspiration' && x.section === 'cover'
              ),
            })),
            scrollSpeed: this.state.scrollSpeed * this.speedFactor,
            temp: data.data.inspirations,
          },
          () => this.setAutoScroll()
        )
      )
  }

  handleManualScroll = () => {
    this.currentPos = window.scrollY || window.pageYOffset
    clearInterval(this.autoScrollTimer)
  }

  setAutoScroll = () => {
    if (this.autoScrollTimer) clearInterval(this.autoScrollTimer)
    this.autoScrollTimer = setInterval(this.scroll, 1000 / this.fps)
  }

  scroll = () => {
    this.currentTime = Date.now()
    if (this.prevTime && !this.isScrolling) {
      this.timeDiff = this.currentTime - this.prevTime
      this.currentPos += this.state.scrollSpeed * this.timeDiff
      if (Math.abs(this.currentPos - this.prevPos) >= this.minDelta) {
        this.isScrolling = true
        window.scrollTo(0, this.currentPos)
        this.isScrolling = false
        this.prevPos = this.currentPos
        this.prevTime = this.currentTime
      }
    } else this.prevTime = this.currentTime
    this.setAutoScroll()
  }

  runLoop = () => {
    this.setState({ data: [...this.state.data, ...this.state.temp] })
  }

  projectOn = () => {
    this.setState({ project: true })
  }

  projectOff = () => {
    this.setState({ project: false })
  }

  render() {
    const { data, project } = this.state
    const { win, sub /* , cover */ } = this.props
    return (
      <>
        {data.length ? (
          <>
            <Routes>
              <Route
                path=':slug'
                element={
                  <Inspiration
                    projectOn={this.projectOn}
                    projectOff={this.projectOff}
                    typeOn={this.props.typeOn}
                    footer={this.props.footer}
                    footInit={this.props.footInit}
                    footOff={this.props.footOff}
                    mScale={this.props.mScale}
                    subReset={this.props.subReset}
                    subInit={this.props.subInit}
                    subOff={this.props.subOff}
                    getImg={this.props.getImg}
                    filterKey={this.props.filterKey}
                    toolInit={this.props.toolInit}
                    toolOff={this.props.toolOff}
                    origin={this.props.subOrigin}
                    initMap={this.props.initMap}
                    win={win}
                    cover={this.props.cover}
                    coverToggle={this.props.coverToggle}
                    coverOff={this.props.coverOff}
                    coverOn={this.props.coverOn}
                    mapPause={this.props.mapPause}
                    mapResume={this.props.mapResume}
                    mapPos={this.props.mapPos}
                    menu={this.props.menu}
                    subMenu={this.props.subMenu}
                    toolReset={this.props.toolReset}
                  />
                }
              />
            </Routes>

            <div
              className={`fullStage eCenter ptLrg inspStage bgBlack blurStage ${
                project ? 'noTouch' : ''
              } ${sub ? 'active' : ''}`}
            >
              <div className='inspireHold  flex flexAEnd'>
                {data.map((item, i) => {
                  return (
                    <Link
                      key={`${item.type}-${i}`}
                      to={`/inspirations/${item.opt2}`}
                      className={`inspireSingle r${item.type} relative eFill`}
                      data-tool={`RUN-INS${i < 10 ? '0' : ''}${i}: ${
                        item.title
                      }`}
                      onMouseEnter={this.props.toolInit}
                      onMouseOut={this.props.toolOff}
                    >
                      <div className={`${item.type}Buff fullWidth eNone`}></div>
                      <div
                        className={`${item.type}Stroke fullWidth fullHeight contentStroke eNone`}
                      ></div>
                      {item.media.length ? (
                        <div className='imgHold fullWidth fullHeight eNone'>
                          <div className='fullWidth fullHeight'>
                            <img
                              className={`${item.type}Img imgCover`}
                              src={this.props.getImg(item.media[0].name, 1000)}
                            ></img>
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </Link>
                  )
                })}
              </div>
            </div>
          </>
        ) : (
          ''
        )}
      </>
    )
  }
}

export default Inspirations
