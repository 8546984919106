import * as ToggleGroup from '@radix-ui/react-toggle-group'
import classNames from 'classnames'
import { Component } from 'react'
import { isMobile } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import Map from './map.js'

class Nav extends Component {
  constructor() {
    super()
    this.timer = null
    this.state = {
      data: [],
      date: [],
      hours: 0,
      location: [],
      mins: 0,
      sec: 0,
    }
  }

  componentDidMount() {
    this.getLoc()
  }

  componentWillUnmount() {}

  getLoc = () => {
    fetch('/api/ip')
      .then(res => res.json())
      .then(response => {
        this.setState({ location: [response.city, response.country] }, () => {
          this.getDate()
        })
      })
      .catch(console.error)
  }

  getDate = () => {
    const now = new Date()
    let day = now.getDate()
    let month = now.getMonth()
    if (day < 10) {
      day = `0${day}`
    }
    if (month + 1 < 10) {
      month = `0${month + 1}`
    } else {
      month = month + 1
    }
    this.setState({ date: [month, day, now.getFullYear()] }, () => {
      // this.getTime();
    })
  }

  chapterChange = e => {
    const el = e.currentTarget.getAttribute('data-sec')
    this.props.chapterChange(el)
  }
  //   getTime=()=>{
  //       var d = new Date()
  //       var hours = d.getHours()
  //       var mins = d.getMinutes()
  //       var sec = d.getSeconds()
  //         if(hours < 10){
  //             hours = `0${hours}`
  //         }
  //         if(mins < 10){
  //             mins = `0${mins}`
  //         }
  //         if(sec < 10){
  //             sec = `0${sec}`
  //         }

  //       this.setState({hours,mins,sec},()=>{
  //         this.timer = setTimeout(this.getTime,100)
  //       })
  //   }

  render() {
    const {
      win,
      menu,
      section,
      chapters,
      subMenu,
      origin,
      subOrigin,
      map,
      mScale,
      chapter,
    } = this.props

    const { date, location /* , hours, mins, sec */ } = this.state

    // const { pathname } = window.location

    return (
      <>
        <div
          id='ui'
          className={`fullFixed eNone`}
          style={{ height: win[1], width: win[0] }}
        >
          {menu && location ? (
            <>
              <div
                className='neg fullFixed eFill'
                style={{ height: win[1], width: win[0] }}
                onClick={this.props.menuOff}
              ></div>
              <div id='menu'>
                <Link to='/about' className='cNone eFill'>
                  <div
                    className={`singleMenu relative eFill ${
                      section === 'about' ? 'active' : ''
                    }`}
                    data-tool='RUN-01: Profile and Credentials'
                    onMouseEnter={
                      isMobile ? () => false : this.props.toolInit.bind(this)
                    }
                    onMouseOut={
                      isMobile ? () => false : this.props.toolOff.bind(this)
                    }
                    onClick={this.props.menuOff.bind(this)}
                  >
                    <div className='dot eNone subDot'></div>

                    <p className='tWhite tFooter eNone onNorm'>
                      {this.props.typeOn('about', 0)}
                    </p>
                  </div>
                </Link>
                {/* <Link to='/work' className='cNone eFill'>
                  <div
                    className={`singleMenu relative eFill ${
                      section === 'work' ? 'active' : ''
                    }`}
                    data-tool='RUN-02: Past and Ongoing Initiatives'
                    onMouseEnter={
                      isMobile ? () => false : this.props.toolInit.bind(this)
                    }
                    onMouseOut={
                      isMobile ? () => false : this.props.toolOff.bind(this)
                    }
                    onClick={this.props.menuOff.bind(this)}
                  >
                    <div
                      className='dot eNone subDot'
                      style={{ animationDelay: `${1 * 0.05}s` }}
                    ></div>

                    <p className='tWhite tFooter eNone onNorm'>
                      {this.props.typeOn('work', 0.05)}
                    </p>
                  </div>
                </Link> */}
                <Link to='/convos' className='cNone eFill'>
                  <div
                    className={`singleMenu relative eFill ${
                      section === 'convos' ? 'active' : ''
                    }`}
                    data-tool='RUN-02: Conversation Logs'
                    onMouseEnter={this.props.toolInit.bind(this)}
                    onMouseOut={this.props.toolOff.bind(this)}
                    onClick={this.props.menuOff.bind(this)}
                  >
                    <div
                      className='dot eNone subDot'
                      style={{ animationDelay: `${2 * 0.05}s` }}
                    ></div>

                    <p className='tWhite tFooter eNone onNorm'>
                      {this.props.typeOn('convos', 0.1)}
                    </p>
                  </div>
                </Link>
                <Link to='/inspirations' className='cNone eFill'>
                  <div
                    className={`singleMenu relative eFill ${
                      section === 'inspirations' ? 'active' : ''
                    }`}
                    // data-tool='RUN-04: Curated stimulants'
                    data-tool='RUN-03: Curated stimulants'
                    onMouseEnter={this.props.toolInit.bind(this)}
                    onMouseOut={this.props.toolOff.bind(this)}
                    onClick={this.props.menuOff.bind(this)}
                  >
                    <div
                      className='dot eNone subDot'
                      style={{ animationDelay: `${3 * 0.05}s` }}
                    ></div>

                    <p className='tWhite tFooter eNone onNorm'>
                      {this.props.typeOn('inspirations', 0.15)}
                    </p>
                  </div>
                </Link>
                <Link to='/thoughts' className='cNone eFill'>
                  <div
                    className={`singleMenu relative eFill ${
                      section === 'thoughts' ? 'active' : ''
                    }`}
                    data-tool='RUN-05: Reflections and Musings'
                    onMouseEnter={
                      isMobile ? () => false : this.props.toolInit.bind(this)
                    }
                    onMouseOut={
                      isMobile ? () => false : this.props.toolOff.bind(this)
                    }
                    onClick={this.props.menuOff.bind(this)}
                  >
                    <div
                      className='dot eNone subDot'
                      style={{ animationDelay: `${4 * 0.05}s` }}
                    ></div>

                    <p className='tWhite tFooter eNone onNorm'>
                      {this.props.typeOn('thoughts', 0.2)}
                    </p>
                  </div>
                </Link>
                <Link to='/contact' className='cNone eFill'>
                  <div
                    className={`singleMenu relative eFill ${
                      section === 'contact' ? 'active' : ''
                    }`}
                    // data-tool='RUN-06: Communication Channel'
                    data-tool='RUN-04: Communication Channel'
                    onMouseEnter={
                      isMobile ? () => false : this.props.toolInit.bind(this)
                    }
                    onMouseOut={
                      isMobile ? () => false : this.props.toolOff.bind(this)
                    }
                    onClick={this.props.menuOff.bind(this)}
                  >
                    <div
                      className='dot eNone subDot'
                      style={{ animationDelay: `${5 * 0.05}s` }}
                    ></div>

                    <p className='tWhite tFooter eNone onNorm'>
                      {this.props.typeOn('contact', 0.25)}
                    </p>
                  </div>
                </Link>
              </div>
            </>
          ) : (
            ''
          )}
          {/* <Link
            // to="/contact"
            to={
              window.location.pathname.includes('/contact') ? '/' : '/contact'
            }
            className='eFill'
          > */}
          <div
            id='menuBut'
            className={`blinkOn menuBut eFill ${menu ? 'active' : ''}`}
            data-status='false'
            data-tool={`activate menu`}
            data-off={'deactivate menu'}
            // className={`blinkOn menuBut eFill ${
            //   window.location.pathname.includes('/contact') ? 'active' : ''
            // }`}
            // data-status={window.location.pathname.includes('/contact')}
            // data-tool={
            //   window.location.pathname.includes('/contact')
            //     ? 'about'
            //     : 'contact'
            // }
            // data-off={
            //   window.location.pathname.includes('/contact')
            //     ? 'about'
            //     : 'contact'
            // }
            onMouseEnter={
              isMobile ? () => false : this.props.toolInit.bind(this)
            }
            onMouseOut={isMobile ? () => false : this.props.toolOff.bind(this)}
            onClick={this.props.menuToggle.bind(this)}
          >
            <div className='dot dotBot eNone'></div>
          </div>
          {/* </Link> */}
          <div id='geo' className='tRight tWhite'>
            <p className='onNorm eNone tFooter mbXs'>
              {this.props.typeOn('Connection:', 0)}
            </p>
            <p className='onNorm eNone tFooter mbXs'>
              {this.props.typeOn(`${date[0]} . ${date[1]} . ${date[2]}`, 0.55)}
            </p>
            <p className='onNorm eNone tFooter'>
              {this.props.typeOn(`${location[0]}`, 0.65)}
            </p>
            <p className='onNorm eNone tFooter mbXs'>
              {this.props.typeOn(`${location[1]}`, 0.8)}
            </p>
          </div>

          <div id='logo'>
            <ToggleGroup.Root
              aria-label='Language'
              className='blinkOn'
              onValueChange={x => this.props.i18n.changeLanguage(x)}
              orientation='horizontal'
              style={{
                animationDelay: '1.3s',
                display: 'flex',
                gap: -1,
                transform: 'translateX(calc(-50% - 0.9em)) rotate(90deg)',
                transformOrigin: 'bottom right',
              }}
              type='single'
              value={this.props.i18n.language}
            >
              {[
                {
                  code: 'en',
                  name: 'English',
                  nameShort: 'En',
                  orientation: 'horizontal',
                },
                {
                  code: 'ja',
                  name: '日本語',
                  nameShort: '日本語',
                  orientation: 'vertical',
                },
              ].map((x, i) => (
                <ToggleGroup.Item
                  aria-label={x.name}
                  className={classNames(
                    'font-mono tWhite tFooter border border-cyan px-2 pt-1.25 pb-1',
                    this.props.i18n.language === x.code
                      ? 'border-solid border-opacity-50 bg-cyan bg-opacity-5'
                      : 'border-dashed border-opacity-50 hover:bg-cyan hover:bg-opacity-10 hover:border-opacity-100 hover:border-solid hover:border-2 hover:px-1.75 hover:pt-1 hover:pb-0.75 hover:animate-pulse pointer-events-auto cursor-pointer transition'
                  )}
                  data-status='false'
                  data-tool={`SET-L${(i + 1).toString().padStart(2, '0')}${
                    this.props.i18n.language === x.code ? ' [✓]' : ''
                  }: ${x.name}`}
                  data-tooldir='left'
                  key={x.code}
                  onMouseEnter={e =>
                    isMobile ? false : this.props.toolInit(e)
                  }
                  onMouseLeave={e => (isMobile ? false : this.props.toolOff(e))}
                  style={{ whiteSpace: 'nowrap' }}
                  value={x.code}
                >
                  {x.orientation === 'vertical'
                    ? x.nameShort.split('').map((x, i) => (
                        <span
                          key={`${x.code}=${i}`}
                          style={{
                            display: 'inline-block',
                            transform: 'rotate(-90deg) translateX(0.1em)',
                          }}
                        >
                          {x}
                        </span>
                      ))
                    : x.nameShort}
                </ToggleGroup.Item>
              ))}
            </ToggleGroup.Root>
            <Link
              to='/'
              className='eFill'
              data-tool='RUN-00: Home'
              data-tooldir='left'
              onMouseEnter={isMobile ? () => false : this.props.toolInit}
              onMouseOut={isMobile ? () => false : this.props.toolOff}
            >
              <div className='logo blinkOn' style={{ animationDelay: '1s' }}>
                <img className='imgFull' src='/images/logo.svg' />
              </div>
            </Link>
          </div>

          <div id='map'>
            {/* {pathname === '/' && ( */}
            <Map
              mobile={this.props.mobile}
              win={win}
              origin={origin}
              subOrigin={subOrigin}
              map={map}
              mScale={mScale}
            />
            {/* )} */}
          </div>

          {chapters.length ? (
            <>
              <div
                id='subBut'
                className={`dotHold subMenuBut eFill ${
                  subMenu ? 'active' : ''
                }`}
                data-status='false'
                data-tool={chapters[0].menuOn}
                data-off={chapters[0].menuOff}
                onMouseEnter={
                  isMobile ? () => false : this.props.toolInit.bind(this)
                }
                onMouseOut={
                  isMobile ? () => false : this.props.toolOff.bind(this)
                }
                onClick={this.props.subMenuToggle.bind(this)}
              >
                <div className='dot dotTop eNone blinkOn'></div>
                <p className='tFooter onNorm tWhite eNone subMenuLabel fullWidth'>
                  {this.props.typeOn(chapters[0].work, 0)}
                </p>
              </div>
              {subMenu && location ? (
                <>
                  <div
                    className='neg fullFixed eFill'
                    style={{ height: win[1], width: win[0] }}
                    onClick={this.props.subMenuOff}
                  ></div>
                  <div id='subMenu'>
                    {chapters.map(chap => {
                      return (
                        <Link
                          key={`chap-${chap.workSlug}-${chap.slug ?? 'index'}`}
                          to={`/${chap.workSlug}/${chap.slug}`}
                          data-sec={chap.slug}
                          className='cNone eFill'
                          onClick={this.chapterChange.bind(this)}
                        >
                          <div
                            className={`singleMenu relative eFill ${
                              chapter === chap.slug ? 'active' : ''
                            }`}
                            data-tool={chap.on}
                            onMouseEnter={
                              isMobile
                                ? () => false
                                : this.props.toolInit.bind(this)
                            }
                            onMouseOut={
                              isMobile
                                ? () => false
                                : this.props.toolOff.bind(this)
                            }
                            onClick={this.props.subMenuOff.bind(this)}
                          >
                            <div className='dot eNone subDot'></div>

                            <p className='tWhite tFooter eNone onNorm'>
                              {this.props.typeOn(chap.title, 0)}
                            </p>
                          </div>
                        </Link>
                      )
                    })}
                  </div>
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </>
    )
  }
}

export default withTranslation()(Nav)
