// import { gql } from 'graphql-tag'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Link, Route, Routes, useLocation } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { useHover, useUpdateEffect, useWindowSize } from 'react-use'

import { Convo } from './convo'
import type { Message } from './types'

import './convos.css'

export const Convos: React.FC<{
  toolInit: (args: any) => any
  toolOff: (args: any) => any
}> = ({ toolInit, toolOff }) => {
  const [convos, setConvos] = useState<any[]>([])
  const [convoIndex, setConvoIndex] = useState<number | null>(null)
  useUpdateEffect(() => {
    if (convos[0] && convoIndex == null) setConvoIndex(0)
  }, [convos])

  const getConvos = async () => {
    const res = await fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            convos {
              id
              title
              type
              contact: opt1
              media {
                name
                url
                rootPage
                section
              }
              messages: options {
                id
                from: title
                html: opt1
                emoji: opt2
                order
                rootpage
              }
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
    const { data: { convos = [] } = {} } = await res.json()
    setConvos(
      convos.reverse().map((convo: any) =>
        convo
          ? {
              ...convo,
              media: convo.media?.filter?.((x: any) => x.rootPage === 'convos'),
              messages: convo.messages
                ?.filter?.((x: any) => x.rootpage === 'convos')
                .reverse() as Message[],
            }
          : convo
      )
    )
  }

  useEffect(() => {
    getConvos()
  }, [])

  const { width } = useWindowSize()
  const { pathname } = useLocation()
  // const [ref, { height }] = useMeasure<HTMLAnchorElement>()

  return useHover(hovered => (
    <div className='absolute top-4 right-10 bottom-16 left-4 text-cyan bg-black border border-solid border-current xs:right-12 sm:grid sm:grid-cols-2 md:top-8 md:right-40 md:bottom-24 md:left-16 max-w-3xl mx-auto'>
      <div
        aria-hidden
        className={`absolute -z-1 border-l border-b border-solid border-current transition-all duration-300 ${
          hovered
            ? 'top-2 right-2 -bottom-1 -left-1'
            : 'opacity-25 top-4 right-4 -bottom-2 -left-2'
        }`}
      ></div>
      <section
        className={`flex flex-col h-full overflow-y-hidden relative ${
          width >= 640 || pathname.endsWith('convos') ? '' : '!hidden'
        }`}
      >
        <div
          className='w-full h-full overflow-y-scroll scrollbar-hide'
          // style={{ paddingBottom: height }}
          style={{ paddingBottom: '5.125rem' }}
        >
          <div className='sticky top-0 z-1 backdrop-filter backdrop-blur backdrop-blur-sm border-b border-solid border-current p-4 font-mono tracking-wider flex items-center space-x-4'>
            <span className='flex-auto'>Convos</span>
          </div>
          <ul className='w-full'>
            {convos.map((x, i) => (
              <CSSTransition
                key={`convos-${x.id}`}
                classNames='fade'
                in={convoIndex != null && i <= convoIndex}
                timeout={300}
                unmountOnExit
                onEntered={() => {
                  if ((convoIndex ?? 0) < convos.length)
                    setConvoIndex((convoIndex ?? 0) + 1)
                }}
              >
                <li className='border-b border-solid border-current'>
                  <Link
                    to={`/convos/${x.id}`}
                    className='block p-4 flex !flex-nowrap space-x-4 hover:bg-cyan hover:bg-opacity-10 hover:animate-pulse'
                    onMouseEnter={e => (isMobile ? false : toolInit(e))}
                    onMouseLeave={e => (isMobile ? false : toolOff(e))}
                    data-status='false'
                    data-tool={`RUN-LOG.${String(x.id).padStart(2, '0')}: ${
                      x.title
                    }`}
                    data-off={'conversation log is active'}
                  >
                    <img
                      className='self-start w-12 aspect-square object-cover border border-solid border-current relative after:content after:absolute after:inset-0 after:bg-current'
                      src={x.media.find((x: any) => x.section === 'cover')?.url}
                      style={{ aspectRatio: '1 / 1' }}
                    ></img>
                    <div className='flex-auto flex flex-col !justify-center overflow-hidden'>
                      <p className='font-sans'>{x.contact}</p>
                      {x.messages.length ? (
                        !!x.messages[0].html && (
                          <div
                            className='w-full font-serif h-[1.5em] mb-[calc(-0.5em-0.125rem)] truncate all:truncate'
                            dangerouslySetInnerHTML={{
                              __html: x.messages[0].html,
                            }}
                          />
                        )
                      ) : (
                        <p className='font-mono tracking-wider text-xs mt-1'>
                          No transmissions
                        </p>
                      )}
                    </div>
                  </Link>
                </li>
              </CSSTransition>
            ))}
          </ul>
          <div className='absolute bottom-0 inset-x-0 block m-4 bg-black'>
            <Link
              // ref={ref}
              to='/contact'
              className='block border border-dashed border-cyan border-opacity-50 p-4 font-mono tracking-wider transition-all hover:bg-cyan hover:bg-opacity-10 hover:border-opacity-100 hover:border-solid hover:border-5 hover:p-3 hover:animate-pulse'
              onMouseEnter={e => (isMobile ? false : toolInit(e))}
              onMouseLeave={e => (isMobile ? false : toolOff(e))}
              data-status='false'
              data-tool={`RUN-04: Communication Channel`}
            >
              Send transmission
            </Link>
          </div>
        </div>
      </section>
      <section
        className={`h-full overflow-hidden sm:border-l sm:border-solid sm:border-current ${
          width < 640 && pathname.endsWith('convos') ? '!hidden' : ''
        }`}
      >
        {pathname.endsWith('convos') ? (
          <div className='w-full h-full flex items-center !justify-center font-mono tracking-wider opacity-50'>
            <span>Select a conversation log</span>
          </div>
        ) : (
          <Routes>
            <Route
              path=':id'
              element={<Convo key={pathname} {...{ toolInit, toolOff }} />}
            ></Route>
          </Routes>
        )}
      </section>
    </div>
  ))[0]
}
