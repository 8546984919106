import { Component, Fragment } from 'react'
import { isMobile } from 'react-device-detect'
import { Route, Routes } from 'react-router-dom'

import Nav from './nav.js'
import About from '../about/about.js'
import Contact from '../contact/contact.js'
import { Convos } from '../convos'
import Inspirations from '../inspirations/inspirations.js'
import Landing from '../landing/landing.js'
import Screen from '../screen/screen.js'
import Thoughts from '../thoughts/thoughts.js'
import './main.css'
import '../landing/landing.css'

class Main extends Component {
  constructor() {
    super()
    this.timer = undefined
    this.target = null
    this.clickedDragger = false
    this.clickedSizer = false
    this.dragMode = ''
    this.initPos = [0, 0]
    this.origin = [0, 0]
    this.x = 0
    this.y = 0
    this.saverTimer = null
    this.saverRate = 180000
    this.state = {
      chapter: '',
      chapters: [],
      count: 0,
      cover: false,
      cross: false,
      data: [],
      finger: false,
      finish: [],
      footer: false,
      height: null,
      hover: false,
      images: [],
      img: 0,
      info: [],
      initF: [0, 0],
      loaded: [],
      mPos: [0, 0],
      mScale: [4, 2],
      map: 0,
      mapActive: false,
      menu: false,
      mobile: false,
      opt: 0,
      origin: [0, 0],
      pan: false,
      ran: 0,
      screensave: false,
      section: '',
      sub: false,
      subMenu: false,
      subOrigin: [0, 0],
      toolDir: null,
      toolTip: '',
      toolbar: false,
      ui: false,
      vPos: [],
      vel: [0, 0],
      width: null,
      win: [window.innerWidth, window.innerHeight],
      xyMax: 0,
      zoom: [],
    }
    this.iCount = 0
    this.images = []
    this.ran = []
    this.hasCountedImages = false
    this.hasPreloadedImages = false
    this.hasLoaded = false
  }

  componentDidMount() {
    this.getInfo()
    this.resizeWin()
    if (isMobile) {
      document.addEventListener('touchstart', this.touchStart, {
        passive: false,
      })
      document.addEventListener('touchend', this.touchStop, { passive: false })
      document.addEventListener('touchmove', this.touchMove, { passive: false })
    } else {
      document.addEventListener('mousemove', this.trackMouse, {
        passive: false,
      })
      document.addEventListener('mousemove', this.saverOff, { passive: false })
      document.addEventListener('mousedown', this.saverOff, { passive: false })
      document.addEventListener('mouseup', this.saverOff, { passive: false })
      document.addEventListener('keydown', this.saverOff, { passive: false })
      document.addEventListener('keyup', this.saverOff, { passive: false })
      document.addEventListener('wheel', this.saverOff, { passive: false })
      this.saverTimer = setTimeout(this.saverOn, this.saverRate)
    }

    window.addEventListener('resize', this.resizeWin, false)
    this.getData()
  }

  saverOn = () => {
    window.clearTimeout(this.saverTimer)
    this.setState({ screensave: true })
  }

  saverOff = () => {
    window.clearTimeout(this.saverTimer)
    this.saverTimer = setTimeout(this.saverOn, this.saverRate)
    if (this.state.screensave) {
      this.setState({ screensave: false })
    }
  }
  // map

  subInit = layer => {
    this.setState({ map: layer, mapActive: true, sub: true }, () => {
      this.moveMap()
    })
  }

  subOff = el => {
    this.setState({ map: el ? 0 : 1, sub: false, xyMax: 0 })
  }

  subReset = level => {
    let subX = 0
    let subY = 0
    subX =
      -1 * this.state.win[0] * (this.state.mScale[1] / 2) +
      this.state.win[0] / 2
    subY =
      -1 * this.state.win[0] * (this.state.mScale[1] / 2) +
      this.state.win[1] / 2
    this.setState({ subOrigin: [subX, subY] }, () => {
      if (level > 0) {
        this.setState({ mapActive: true }, () => {
          this.moveMap()
        })
      }
    })
  }

  initMap = (size, level) => {
    let x = 0
    let y = 0
    let subX = 0
    let subY = 0

    this.setState({ map: level }, () => {
      x =
        -1 * this.state.win[0] * (this.state.mScale[0] / 2) +
        this.state.win[0] / 2
      y =
        -1 * this.state.win[0] * (this.state.mScale[0] / 2) +
        this.state.win[1] / 2
      subX =
        -1 * this.state.win[0] * (this.state.mScale[1] / 2) +
        this.state.win[0] / 2
      subY =
        -1 * this.state.win[0] * (this.state.mScale[1] / 2) +
        this.state.win[1] / 2
      this.setState({ origin: [x, y], subOrigin: [subX, subY] }, () => {
        if (level > 0) {
          this.setState({ mapActive: true }, () => {
            this.moveMap()
          })
        } else {
          this.setState({ mapActive: false })
        }
      })
    })
  }

  moveMap = () => {
    clearTimeout(this.timer)
    if (this.state.mapActive && !isMobile) {
      let speed = 20
      let size = this.state.mScale[0]
      if (this.state.hover) {
        speed = 15
      }
      if (this.state.sub) {
        size = this.state.mScale[1]
      }
      if (this.state.map === 1) {
        let cx = this.state.origin[0] - this.state.vel[0] * speed
        let cy = this.state.origin[1] - this.state.vel[1] * speed
        if (cx > 0) cx = 0
        if (cy > 0) cy = 0
        if (cx < this.state.win[0] * (-1 * size) + this.state.win[0])
          cx = this.state.win[0] * (-1 * size) + this.state.win[0]
        if (cy < this.state.win[0] * (-1 * size) + this.state.win[1])
          cy = this.state.win[0] * (-1 * size) + this.state.win[1]
        this.setState({ origin: [cx, cy] }, () => {
          this.timer = setTimeout(this.moveMap, 15)
        })
      } else if (this.state.map === 2) {
        let cx = this.state.subOrigin[0] - this.state.vel[0] * speed
        let cy = this.state.subOrigin[1] - this.state.vel[1] * speed
        if (cx > 0) cx = 0
        if (cy > 0) cy = 0
        if (cx < this.state.win[0] * (-1 * size) + this.state.win[0])
          cx = this.state.win[0] * (-1 * size) + this.state.win[0]
        if (cy < this.state.win[0] * (-1 * size) + this.state.win[1])
          cy = this.state.win[0] * (-1 * size) + this.state.win[1]
        this.setState({ subOrigin: [cx, cy] }, () => {
          this.timer = setTimeout(this.moveMap, 15)
        })
      }
    }
  }

  mapPause = (/* e */) => {
    this.setState({ map: 0 })
  }

  mapResume = level => {
    const el = document.getElementsByClassName(`map-${level}`)[0]
    if (el) {
      el.style.transition = 'none'
    }
    this.setState({ map: parseInt(level) }, () => {
      this.moveMap()
      this.toolReset()
    })
  }

  mapPos = (x, y, w, h, level) => {
    if (parseInt(level) === 1) {
      this.setState({
        origin: [x + this.state.win[0] / 2, y + this.state.win[1] / 2],
      })
    } else if (parseInt(level) === 2) {
      const el = document.getElementsByClassName(`map-${level}`)[0]
      if (el) {
        el.style.transition = 'all .5s ease-out'
      }
      this.setState({
        subOrigin: [
          x + this.state.win[0] / 2 - w,
          y + this.state.win[1] / 2 - h,
        ],
      })
    }
  }

  // Utilities
  secChange = text => {
    if (text !== '') {
      this.setState({ section: text, ui: true })
    } else if (text === '' && this.state.count === 0) {
      this.setState({ section: '', ui: false })
    }
    this.menuOff()
  }

  getImg = (name, size) =>
    `https://zettoplus.imgix.net/${name}?auto=compress&w=${size}&fit=clip`

  typeOn = (text, num, count) =>
    text?.split('').map((x, i, arr) =>
      i === arr.length - 1 && count ? (
        <span
          key={`toolLetter-${i}`}
          className='toolLetter end'
          style={{ animationDelay: `${i * 0.015 + num}s` }}
          onAnimationEnd={this.animCount}
        >
          {x}
        </span>
      ) : (
        <span
          key={`toolLetter-${i}`}
          className='toolLetter'
          style={{ animationDelay: `${i * 0.015 + num}s` }}
        >
          {x}
        </span>
      )
    ) ?? []

  animCount = num => this.setState({ count: num ? 3 : this.state.count + 1 })

  filterKey = (array, sec, term) => array.filter(x => x[sec] === term)

  heightOn = () => this.setState({ height: true })
  heightOff = () => this.setState({ height: false })

  widthOn = () => this.setState({ width: true })
  widthOff = () => this.setState({ width: false })

  // Mouse
  preventBehavior = e => {
    e.preventDefault()
  }

  resizeWin = () =>
    this.setState({
      mScale: window.innerWidth < 800 ? [8, 3] : [4, 2],
      mobile: window.innerWidth < 800,
      win: [window.innerWidth, window.innerHeight],
    })

  // desktop
  trackMouse = e => {
    const vx =
      (((e.clientX - this.state.win[0] / 2) / this.state.win[0]) * 2) ** 3
    const vy =
      (((e.clientY - this.state.win[1] / 2) / this.state.win[1]) * 2) ** 3
    if (!this.state.cross) {
      this.setState({ cross: true })
    }
    this.setState({ mPos: [e.clientX, e.clientY], vel: [vx, vy] }, () => {})
  }

  // Mobile
  touchStart = e => {
    if (this.state.map > 0) {
      if (!this.state.cover && !this.state.menu && !this.state.subMenu) {
        clearTimeout(this.timer)
        this.setState({
          finger: true,
          initF: [e.touches[0].clientX, e.touches[0].clientY],
          pan: true,
        })
      }
    }
  }

  touchStop = (/* e */) => {
    if (this.state.pan) this.setState({ finger: false, pan: false })
  }

  touchMove = e => {
    if (this.state.pan) {
      if (this.state.finger) {
        let size = this.state.mScale[0]
        if (this.state.sub) {
          size = this.state.mScale[1]
        }
        const vx = (e.touches[0].clientX - this.state.initF[0]) * -1
        const vy = (e.touches[0].clientY - this.state.initF[1]) * -1
        if (this.state.map === 1) {
          let cx = this.state.origin[0] - vx
          let cy = this.state.origin[1] - vy
          if (cx > 0) cx = 0
          if (cy > 0) cy = 0
          if (cx < this.state.win[0] * (-1 * size) + this.state.win[0])
            cx = this.state.win[0] * (-1 * size) + this.state.win[0]
          if (cy < this.state.win[0] * (-1 * size) + this.state.win[1])
            cy = this.state.win[0] * (-1 * size) + this.state.win[1]
          this.setState(
            {
              initF: [e.touches[0].clientX, e.touches[0].clientY],
              origin: [cx, cy],
            },
            () => {
              // this.timer=setTimeout(this.moveMap, 15)
            }
          )
        } else if (this.state.map === 2) {
          let cx = this.state.subOrigin[0] - vx
          let cy = this.state.subOrigin[1] - vy
          if (cx > 0) cx = 0
          if (cy > 0) cy = 0
          if (cx < this.state.win[0] * (-1 * size) + this.state.win[0])
            cx = this.state.win[0] * (-1 * size) + this.state.win[0]
          if (cy < this.state.win[0] * (-1 * size) + this.state.win[1])
            cy = this.state.win[0] * (-1 * size) + this.state.win[1]
          this.setState(
            {
              initF: [e.touches[0].clientX, e.touches[0].clientY],
              subOrigin: [cx, cy],
            },
            () => {
              // this.timer=setTimeout(this.moveMap, 15)
            }
          )
        }
      }
    }
  }

  // UI

  initUI = () => this.setState({ ui: true })

  toolInit = e => {
    if (!isMobile) {
      let text = ''
      e.currentTarget.classList.add('hoverOn')
      if (e.currentTarget.getAttribute('data-tooldir')) {
        this.setState({ toolDir: true })
      }
      if (e.currentTarget.getAttribute('data-status')) {
        if (e.currentTarget.getAttribute('data-status') === 'true') {
          text = e.currentTarget.getAttribute('data-off')
        } else {
          text = e.currentTarget.getAttribute('data-tool')
        }
      } else {
        text = e.currentTarget.getAttribute('data-tool')
      }
      this.setState({ hover: true, toolbar: true }, () => {
        this.toolOn(text)
      })
    }
  }

  toolOn = text => {
    if (!isMobile) {
      this.setState({ toolTip: text })
    }
  }

  toolOff = e => {
    if (!isMobile) {
      e.currentTarget.classList.remove('hoverOn')
      this.setState({ hover: false, toolDir: null, toolbar: false })
    }
  }

  footInit = e => {
    const text = e.currentTarget.getAttribute('data-tool')
    e.currentTarget.classList.add('hoverOn')
    this.setState({ footer: true, hover: true }, () => {
      this.footOn(text)
    })
  }

  footOn = text => {
    this.setState({ toolTip: text })
  }

  footOff = e => {
    e.currentTarget.classList.remove('hoverOn')
    this.setState({ footer: false, hover: false })
  }

  toolReset = () => {
    this.setState(
      { footer: false, hover: false, toolTip: '', toolbar: false },
      () => {
        const el = document.getElementsByClassName('hoverOn')[0]
        if (el) {
          el.classList.remove('hoverOn')
        }
      }
    )
  }

  // main menu
  menuToggle = () => {
    const el = document.getElementById('menuBut')
    if (el) {
      if (this.state.subMenu) this.subMenuOff()
      this.setState(
        { menu: !this.state.menu, toolTip: '', toolbar: true },
        () => {
          el.setAttribute('data-status', this.state.menu)
          if (this.state.menu) {
            this.toolOn(el.getAttribute('data-off'))
          } else {
            this.toolOn(el.getAttribute('data-tool'))
          }
        }
      )
    }
  }

  menuOff = () => {
    this.setState({ menu: false, toolbar: false }, () => {
      const el = document.getElementsByClassName('menuBut')[0]
      if (el) {
        el.setAttribute('data-status', false)
      }
    })
  }

  subMenuToggle = () => {
    const el = document.getElementById('subBut')
    if (el) {
      if (this.state.menu) this.menuOff()
      this.setState(
        { subMenu: !this.state.subMenu, toolTip: '', toolbar: true },
        () => {
          el.setAttribute('data-status', this.state.subMenu)
          if (this.state.subMenu) {
            this.toolOn(el.getAttribute('data-off'))
          } else {
            this.toolOn(el.getAttribute('data-tool'))
          }
        }
      )
    }
  }

  subMenuOff = () => {
    this.setState({ subMenu: false, toolbar: false }, () => {
      const el = document.getElementsByClassName('subMenuBut')[0]
      if (el) {
        el.setAttribute('data-status', false)
      }
    })
  }

  subMenuOn = () => {
    this.setState({ subMenu: true, toolbar: true }, () => {
      const el = document.getElementsByClassName('subMenuBut')[0]
      if (el) {
        el.setAttribute('data-status', true)
      }
    })
  }

  coverToggle = el => {
    this.setState(
      { cover: !this.state.cover, toolTip: '', toolbar: true },
      () => {
        this.toolReset()
        el.setAttribute('data-status', this.state.cover)
        if (this.state.cover) {
          this.toolOn(el.getAttribute('data-off'))
        } else {
          this.toolOn(el.getAttribute('data-tool'))
        }
      }
    )
  }

  coverOff = () => {
    this.setState({ cover: false, toolbar: false }, () => {
      const el = document.getElementsByClassName('coverBut')[0]
      if (el) {
        el.setAttribute('data-status', false)
      }
    })
  }

  coverOn = () => {
    this.setState({ cover: true }, () => {
      const el = document.getElementsByClassName('coverBut')[0]
      if (el) {
        el.setAttribute('data-status', true)
      }
    })
  }

  // Page Dependent
  getInfo = () => {
    fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            info {
              city
              state
              email
              instagram
              about
              media(filter: { rootPage: "info" }) {
                url
              }
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then(res => res.json())
      .then(data =>
        this.setState(
          {
            info: data.data.info,
          },
          () => {}
        )
      )
  }

  // chapters
  chaptersInit = (items, open) => {
    this.setState({ chapters: items }, () => {
      if (open) {
        this.subMenuOn()
      }
    })
  }

  chaptersOff = () => {
    this.setState({ chapters: [] })
  }

  chapterChange = curr => {
    this.setState({ chapter: curr })
  }

  // toolPop

  popInit = () => {
    const el = document.getElementsByClassName('winLink')
    if (el?.length) {
      for (let i = 0; i < el.length; i++) {
        el[i].classList.add('eFill')
        el[i].setAttribute('data-tool', ' ')
        el[i].addEventListener('mouseenter', this.toolInit)
        el[i].addEventListener('mouseout', this.toolOff)
        el[i].addEventListener('click', this.popUp)
      }
    }
  }

  popUp = e => {
    const src = e.currentTarget.getAttribute('data-src')
    if (e.currentTarget.getAttribute('data-on') !== 'true') {
      const type = e.currentTarget.getAttribute('data-type')
      e.currentTarget.classList.add('active')
      e.currentTarget.setAttribute('data-on', 'true')
      e.currentTarget.setAttribute('id', src)
      const newDiv = document.createElement('div')
      newDiv.setAttribute('data-on', src)
      newDiv.style.left = `${this.state.win[0] / 2}px`
      newDiv.style.top = `${window.scrollY + this.state.win[1] / 2}px`
      newDiv.classList.add('singlePop', 'draggable', 'eFill', type, 'blinkOn')
      let inner = ''
      if (type === 'imageLink') {
        inner = `<div class="fullWidth relative"><div class="dragger eFill dragBuff"></div><div class="popStroke bWhite bSm bgBlack eNone"></div><div class="imageHold fullWidth eNone"><img class="imgFull eNone" src="${src}"></div></div>`
      } else {
        inner = `<div class="dragger eFill dragBuff"></div><div class="popStroke bWhite bSm bgBlack dragger"></div><div class="imageHold fullWidth eNone"><div class="rWide relative eFill"><iframe src="https://www.youtube-nocookie.com/embed/${src}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div></div>`
      }
      const dot = document.createElement('div')
      dot.classList.add('closeHold', 'active', 'bgBlack', 'eFill', 'dragger')
      dot.setAttribute('data-tool', 'Remove Window')
      dot.setAttribute('data-tooldir', 'left')
      dot.addEventListener('click', this.popOff)
      dot.addEventListener('mouseenter', this.toolInit)
      dot.addEventListener('mouseout', this.toolOff)
      const sizer = document.createElement('div')
      sizer.classList.add('sizer', 'active', 'eFill')
      sizer.setAttribute('data-tool', 'Resize')
      sizer.addEventListener('mouseenter', this.toolInit)
      sizer.addEventListener('mouseout', this.toolOff)
      newDiv.innerHTML = inner
      dot.innerHTML = `<div class="dot eNone"></div>`
      newDiv.appendChild(dot)
      newDiv.appendChild(sizer)
      document.getElementsByClassName('popUpHold')[0]?.appendChild(newDiv)
    } else {
      const el = document.querySelector(`.singlePop[data-on="${src}"]`)
      if (el) {
        el.parentNode.removeChild(el)
        e.currentTarget.setAttribute('data-on', 'false')
        e.currentTarget.classList.remove('active')
      }
    }
  }

  popOff = e => {
    const parent = e.currentTarget.parentNode
    const el = document.getElementById(`${parent.getAttribute('data-on')}`)
    if (el) {
      el.setAttribute('data-on', 'false')
      el.classList.remove('active')
    }
    parent.parentNode.removeChild(parent)
    this.toolReset()
  }

  popReset = () => {
    const el = document.getElementsByClassName('popUpHold')[0]
    el.innerHTML = ''
  }

  dragOn = () => {
    if (isMobile) {
      document.addEventListener('touchstart', this.mouseDown, {
        passive: false,
      })
      document.addEventListener('touchend', this.mouseUp, { passive: false })
      document.addEventListener('touchmove', this.mouseMove, { passive: false })
    } else {
      document.addEventListener('mousedown', this.mouseDown, { passive: false })
      document.addEventListener('mouseup', this.mouseUp, { passive: false })
      document.addEventListener('mousemove', this.mouseMove, { passive: false })
    }
  }

  dragOff = () => {
    if (isMobile) {
      document.addEventListener('touchstart', this.mouseDown)
      document.addEventListener('touchend', this.mouseUp)
      document.addEventListener('touchmove', this.mouseMove)
    } else {
      document.addEventListener('mousedown', this.mouseDown)
      document.addEventListener('mouseup', this.mouseUp)
      document.addEventListener('mousemove', this.mouseMove)
    }
  }

  mouseDown = e => {
    this.clickedDragger = false
    this.clickedSizer = false

    const path = e.path || (e.composedPath && e.composedPath())
    for (let i = 0; path[i] !== document.body; i++) {
      if (path[i].classList.contains('dragger')) {
        this.clickedDragger = true
      } else if (path[i].classList.contains('sizer')) {
        this.clickedSizer = true
      } else if (
        this.clickedDragger &&
        path[i].classList.contains('draggable')
      ) {
        e.preventDefault()
        this.target = path[i]
        this.target.classList.add('dragging')
        this.bringTop(this.target)
        this.dragMode = 'move'
        this.x =
          (e.clientX || e.touches[0].clientX) -
          this.target.style.left.slice(0, -2)
        this.y =
          (e.clientY || e.touches[0].clientY) -
          this.target.style.top.slice(0, -2)
        return
      } else if (this.clickedSizer && path[i].classList.contains('draggable')) {
        e.preventDefault()
        this.target = path[i]
        this.target.classList.add('dragging')
        this.target.style.zIndex =
          parseInt(this.target.style.zIndex) +
          parseInt(this.target.parentElement.childElementCount)
        this.bringTop(this.target)
        this.dragMode = 'size'
        this.x =
          (e.clientX || e.touches[0].clientX) -
          this.target.style.left.slice(0, -2)
        this.y =
          (e.clientY || e.touches[0].clientX) -
          this.target.style.top.slice(0, -2) +
          window.scrollY
        return
      }
    }
  }

  mouseUp = (/* e */) => {
    this.target?.classList.remove('dragging')
    this.target = null
    this.dragMode = null
  }

  mouseMove = e => {
    if (this.target === null) return
    e.preventDefault()
    if (this.dragMode === 'move') {
      this.target.style.left = `${
        (e.clientX || e.touches?.[0]?.clientX || 0) - this.x
      }px`
      this.target.style.top = `${
        (e.clientY || e.touches?.[0]?.clientY || 0) - this.y
      }px`
    } else if (this.dragMode === 'size' && !isMobile) {
      const pRect = this.target.parentElement.getBoundingClientRect()
      // const pHeight = pRect.bottom - pRect.top
      const tgtRect = this.target.getBoundingClientRect()
      // const orginX = tgtRect.left - pRect.left
      // const orginY = tgtRect.top - pRect.top
      const tgtWidth =
        (((e.clientX || e.touches?.[0]?.clientYf || 0) - tgtRect.left) /
          pRect.width) *
        100
      this.target.style.width = `${tgtWidth}%`
    }
  }

  bringTop = el => {
    el.style.zIndex =
      Math.max(
        0,
        ...Array.from(document.querySelectorAll('.draggable')).map(
          x => x.style.zIndex
        )
      ) + 1
  }

  getData = () => {
    ;(async () => {
      await this.getOptions()
      this.countImages([...this.state.zoom])
    })()

    // const setData = data => {
    // this.setState({ data: data.medias }, () => {
    // await this.getOptions()
    // this.countImages([...this.state.zoom])
    // })
    // }

    // const _data = window.localStorage.getItem('landing-data')
    // if (_data && _data !== 'undefined') setData(JSON.parse(_data) ?? {})

    // fetch('/api/graphql', {
    //   body: JSON.stringify({
    //     query: /* gql */ `
    //       query {
    //         medias(filter: { type: "images" }) {
    //           url
    //           name
    //           rootPage
    //         }
    //       }
    //     `,
    //   }),
    //   headers: { 'Content-Type': 'application/json' },
    //   method: 'POST',
    // })
    //   .then(res => res.json())
    //   .then(data => {
    //     window.localStorage.setItem('landing-data', JSON.stringify(data.data))
    //     setData(data.data)
    //   })
  }

  getOptions = () => {
    const setOptions = data => {
      this.setState({ zoom: data.options }, () => {
        this.getVideo()
      })
    }

    const _data = window.localStorage.getItem('landing-options')
    if (_data && _data !== 'undefined') setOptions(JSON.parse(_data))

    return fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            options(filter: { type: "zoom", rootpage: "landing" }) {
              title
              opt1
              id
              media(filter: { section: "zoom", rootpage: "landing" }) {
                url
                name
              }
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then(res => res.json())
      .then(data => {
        window.localStorage.setItem(
          'landing-options',
          JSON.stringify(data.data)
        )
        setOptions(data.data)
      })
  }

  getVideo = () => {
    const setVideo = data => {
      this.setState({
        count: this.state.count || 1,
        finish: data.medias,
      })
    }

    const _data = window.localStorage.getItem('landing-video')
    if (_data && _data !== 'undefined') setVideo(JSON.parse(_data))

    fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            medias(filter: { rootPage: "finish", section: "finish" }) {
              url
              name
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then(res => res.json())
      .then(data => {
        window.localStorage.setItem('landing-video', JSON.stringify(data.data))
        setVideo(data.data)
      })
  }

  countImages = (pro = []) => {
    if (!this.hasCountedImages) {
      this.hasCountedImages = true

      pro.forEach(img => {
        this.iCount++
        if (img.rootPage !== 'quill' && img.rootPage !== 'landing') {
          this.ran.push(img.name)
        }
        this.images.push(img.name)
      })

      this.preloadImg()
    }
  }

  preloadImg = async () => {
    if (!this.hasPreloadedImages) {
      this.hasPreloadedImages = true
      const interval = 10
      await Promise.all(
        this.images.map(
          (name, i) =>
            new Promise(resolve => {
              setTimeout(() => {
                const img = new Image()
                img.src = this.getImg(name, 1200)
                this.setState({
                  loaded: Math.round(((i + 1) / this.images.length) * 100),
                })
                resolve()
              }, i * interval)
            })
        )
      )
      this.setState({ images: this.ran }, () => this.loadDone())
    }
  }

  loadDone = () => {
    if (!this.hasLoaded) {
      this.hasLoaded = true
      window.setTimeout(() => this.setState({ loaded: 100 }), 100)
    }
  }

  startUI = () => {
    this.initUI()
    document.removeEventListener('mousemove', this.startUI)
  }

  animCountTmp() {
    const curr = this.state.opt + 1

    if (curr === 1) {
      if (!isMobile)
        document.addEventListener('mousemove', this.startUI, { passive: false })
    }

    if (curr === 2 && isMobile) this.initUI()

    if (curr === this.state.zoom.length) this.animCount()
    else this.setState({ opt: curr })
  }

  imgCount() {
    const curr = this.state.img + 1

    if (curr === this.state.zoom[this.state.opt].media.length)
      this.animCountTmp()
    else this.setState({ img: curr })
  }

  ranCount = () => {
    const curr = this.state.ran + 1
    if (curr === this.state.images.length) {
      this.animCountTmp()
    } else {
      this.setState({ ran: curr })
    }
  }

  render() {
    const {
      height,
      info,
      win,
      mPos,
      ui,
      cross,
      toolbar,
      menu,
      section,
      origin,
      vel,
      subOrigin,
      footer,
      chapters,
      subMenu,
      chapter,
      width,
      toolDir,
      map,
      mScale,
      mobile,
      screensave,
      count,
      opt,
      img,
      zoom,
      loaded,
    } = this.state

    // const pathname = window.location.pathname

    return (
      <div>
        {loaded === 100 && count === 1 && (
          <Fragment key={`landingOption-${opt}-${img}`}>
            {zoom[opt].title === 'text' ? (
              <div
                style={{ inset: 0, position: 'fixed', transform: 'scale(10%)' }}
              >
                <h1
                  className='enterLeaveSlow tUpper introItem tWhite tCenter'
                  onAnimationEnd={() => this.animCountTmp()}
                  style={{ fontSize: '45vw', width: '1000vw' }}
                >
                  {zoom[opt].opt1}
                </h1>
              </div>
            ) : (
              zoom[opt].media?.length && (
                <div
                  className='enterLeaveFastImg introItem fullWidth fullHeight'
                  onAnimationEnd={() => this.imgCount()}
                >
                  <img
                    className='imgContain'
                    src={this.getImg(zoom[opt].media[img].name, 1200)}
                  ></img>
                </div>
              )
            )}
          </Fragment>
        )}
        {count >= 2 && (
          <>
            {!!screensave && (
              // <Route
              //   path='/'
              //   element={
              <Screen
                typeOn={this.typeOn}
                win={this.state.win}
                toolInit={this.toolInit}
                toolOff={this.toolOff}
                getImg={this.getImg}
              />
              //   }
              // />
            )}
            <div
              id='cross'
              className='fullFixed eNone'
              style={{ height: win[1], width: win[0], zIndex: 999 }}
            >
              <div
                id='crossX'
                className={`crossX crossHair ${
                  ui && cross && !toolbar && !footer && !screensave
                    ? 'active'
                    : ''
                }`}
                style={{
                  height: 0,
                  left: mPos[0],
                  top: mPos[1],
                  width: win[0] * 2,
                }}
              ></div>
              <div
                id='crossY'
                className={`crossY crossHair ${
                  ui && cross && !toolbar && !footer && !screensave
                    ? 'active'
                    : ''
                }`}
                style={{
                  height: win[1] * 2,
                  left: mPos[0],
                  top: mPos[1],
                  width: 0,
                }}
              ></div>
            </div>
            {toolbar && !footer && !screensave && !isMobile ? (
              <div
                id='toolBar'
                key={`toolbar-${this.state.toolTip}`}
                className={`toolBar tWhite eNone blinkFast`}
                style={{
                  left: mPos[0],
                  top: mPos[1],
                  transform: toolDir ? `translate(-130%)` : `translateX(40px)`,
                }}
              >
                <p id='toolText' className='tUpper tFooter onNorm'>
                  {this.typeOn(this.state.toolTip, 0)}
                </p>
              </div>
            ) : (
              ''
            )}
            {footer && !toolbar && !screensave && !isMobile ? (
              <div
                id='footer'
                key={`footer-${this.state.toolTip}`}
                className={`footer tWhite eNone tP`}
                dangerouslySetInnerHTML={{ __html: this.state.toolTip }}
              ></div>
            ) : (
              ''
            )}
            {ui ? (
              <>
                {!mobile ? (
                  <div id='debug' className='tWhite eNone tRight'>
                    <p className='tFooter onNorm'>
                      {this.typeOn(`COR: `, 0.9)}
                      <span
                        className='textFadeOn'
                        style={{ animationDelay: '.9s' }}
                      >
                        {mPos[0]}, {mPos[1]}
                      </span>
                    </p>
                    <p className='tFooter onNorm'>
                      {this.typeOn(`speed: `, 1.05)}
                      <span
                        className='textFadeOn'
                        style={{ animationDelay: '1.05s' }}
                      >
                        {vel[0].toFixed(2)}, {vel[1].toFixed(2)}
                      </span>
                    </p>
                    <p className='tFooter onNorm'>
                      {this.typeOn(`pos1: `, 1.15)}
                      <span
                        className='textFadeOn'
                        style={{ animationDelay: '1.15s' }}
                      >
                        {origin[0].toFixed(0)}, {origin[1].toFixed(0)}
                      </span>
                    </p>
                    <p className='tFooter onNorm'>
                      {this.typeOn(`pos2: `, 1.3)}
                      <span
                        className='textFadeOn'
                        style={{ animationDelay: '1.3s' }}
                      >
                        {subOrigin[0].toFixed(0)}, {subOrigin[1].toFixed(0)}
                      </span>
                    </p>
                  </div>
                ) : (
                  ''
                )}
                {/* {pathname === '/' && ( */}
                <Nav
                  mobile={this.state.mobile}
                  chapters={chapters}
                  menuOff={this.menuOff}
                  subMenuOff={this.subMenuOff}
                  section={section}
                  typeOn={this.typeOn}
                  menuToggle={this.menuToggle}
                  subMenuToggle={this.subMenuToggle}
                  menu={this.state.menu}
                  subMenu={this.state.subMenu}
                  getImg={this.getImg}
                  win={this.state.win}
                  toolInit={this.toolInit}
                  toolOff={this.toolOff}
                  chapterChange={this.chapterChange}
                  chapter={chapter}
                  origin={origin}
                  subOrigin={subOrigin}
                  map={map}
                  mScale={mScale}
                />
                {/* )} */}
              </>
            ) : (
              ''
            )}
            {info ? (
              <div
                id='appContain'
                className='relative'
                style={{ height: 'auto', width: win[0] }}
              >
                <div
                  id='bg'
                  className='bgBlack'
                  style={{ height: win[1], width: win[0] }}
                ></div>
                {/* <Route
                  path='/inspirations/*'
                  render={() => (
                    <Inspiration
                      typeOn={this.typeOn}
                      footer={this.state.footer}
                      footInit={this.footInit}
                      footOff={this.footOff}
                      mScale={this.state.mScale}
                      subReset={this.subReset}
                      subInit={this.subInit}
                      subOff={this.subOff}
                      getImg={this.getImg}
                      filterKey={this.filterKey}
                      toolInit={this.toolInit}
                      toolOff={this.toolOff}
                      origin={this.state.subOrigin}
                      initMap={this.initMap}
                      win={win}
                      cover={this.state.cover}
                      coverToggle={this.coverToggle}
                      coverOff={this.coverOff}
                      coverOn={this.coverOn}
                      mapPause={this.mapPause}
                      mapResume={this.mapResume}
                      mapPos={this.mapPos}
                      menu={this.state.menu}
                      subMenu={this.state.subMenu}
                      toolReset={this.toolReset}
                    />
                  )}
                /> */}
                <div
                  id='content'
                  style={{
                    height: height ? 'auto' : win[1],
                    overflowX: 'hidden',
                    overflowY: height ? 'visible' : 'hidden',
                    width: width ? 'auto' : win[0],
                  }}
                  className={`bgBlack contentStage ${
                    menu || subMenu ? 'menuActive' : ''
                  }`}
                >
                  <div className='popUpHold fullHeight eNone fullWidth'></div>
                  <Routes>
                    <Route
                      path='convos/*'
                      element={
                        <Convos
                          // dragOn={this.dragOn}
                          // dragOff={this.dragOff}
                          // popInit={this.popInit}
                          // popReset={this.popReset}
                          // footer={footer}
                          // footInit={this.footInit}
                          // footOff={this.footOff}
                          toolInit={this.toolInit}
                          toolOff={this.toolOff}
                          // heightOn={this.heightOn}
                          // heightOff={this.heightOff}
                          // typeOn={this.typeOn}
                          // secChange={this.secChange}
                          // getImg={this.getImg}
                          // sub={this.state.sub}
                          // win={this.state.win}
                          // mScale={this.state.mScale}
                          // subReset={this.subReset}
                          // subInit={this.subInit}
                          // subOff={this.subOff}
                          // origin={this.state.subOrigin}
                          // initMap={this.initMap}
                          // mapPause={this.mapPause}
                          // mapResume={this.mapResume}
                          // mapPos={this.mapPos}
                          // chaptersInit={this.chaptersInit}
                          // chapter={chapter}
                          // info={this.state.info}
                          // filterKey={this.filterKey}
                          // chapterChange={this.chapterChange}
                          // toolReset={this.toolReset}
                        />
                      }
                    />
                    <Route
                      path='inspirations/*'
                      element={
                        <Inspirations
                          footer={footer}
                          footInit={this.footInit}
                          footOff={this.footOff}
                          toolInit={this.toolInit}
                          toolOff={this.toolOff}
                          heightOn={this.heightOn}
                          heightOff={this.heightOff}
                          typeOn={this.typeOn}
                          secChange={this.secChange}
                          chapterChange={this.chapterChange}
                          getImg={this.getImg}
                          sub={this.state.sub}
                          win={this.state.win}
                          mScale={this.state.mScale}
                          subReset={this.subReset}
                          subInit={this.subInit}
                          subOff={this.subOff}
                          subOrigin={this.state.subOrigin}
                          initMap={this.initMap}
                          cover={this.state.cover}
                          coverToggle={this.coverToggle}
                          coverOff={this.coverOff}
                          coverOn={this.coverOn}
                          mapPause={this.mapPause}
                          mapResume={this.mapResume}
                          mapPos={this.mapPos}
                          toolReset={this.toolReset}
                        />
                      }
                    />
                    {/* <Route path='/work' render={()=><Work mobile={mobile} heightOn={this.heightOn} heightOff={this.heightOff} chaptersInit={this.chaptersInit} chaptersOff={this.chaptersOff} footer={footer} footInit={this.footInit} footOff={this.footOff} toolInit={this.toolInit} toolOff={this.toolOff} filterKey={this.filterKey} origin={origin} sub={this.state.sub} subOrigin={this.state.subOrigin} subInit={this.subInit} subOff={this.subOff} subReset={this.subReset} initMap={this.initMap} typeOn={this.typeOn} secChange={this.secChange} getImg={this.getImg} win={this.state.win} mScale={this.state.mScale}  coverToggle={this.coverToggle} cover={this.state.cover} coverOff={this.coverOff} coverOn={this.coverOn}  section={this.state.section} chapter={chapter} chapterChange={this.chapterChange} mapPause={this.mapPause} mapResume={this.mapResume} mapPos={this.mapPos} toolReset={this.toolReset}/>}/> */}
                    <Route
                      path='contact'
                      element={
                        <Contact
                          initMap={this.initMap}
                          heightOn={this.heightOn}
                          heightOff={this.heightOff}
                          typeOn={this.typeOn}
                          filterKey={this.filterKey}
                          secChange={this.secChange}
                          getImg={this.getImg}
                          win={this.state.win}
                          toolReset={this.toolReset}
                          info={info}
                          toolInit={this.toolInit}
                          toolOff={this.toolOff}
                        />
                      }
                    />
                    <Route
                      path='about'
                      // path='/'
                      element={
                        <About
                          initMap={this.initMap}
                          dragOn={this.dragOn}
                          dragOff={this.dragOff}
                          popReset={this.popReset}
                          popInit={this.popInit}
                          heightOn={this.heightOn}
                          heightOff={this.heightOff}
                          typeOn={this.typeOn}
                          filterKey={this.filterKey}
                          secChange={this.secChange}
                          getImg={this.getImg}
                          win={this.state.win}
                          toolReset={this.toolReset}
                          toolInit={this.toolInit}
                          toolOff={this.toolOff}
                        />
                      }
                    />

                    <Route
                      path='thoughts/*'
                      element={
                        <Thoughts
                          initMap={this.initMap}
                          dragOn={this.dragOn}
                          dragOff={this.dragOff}
                          popReset={this.popReset}
                          popInit={this.popInit}
                          subMenuOn={this.subMenuOn}
                          chaptersInit={this.chaptersInit}
                          subMenuToggle={this.subMenuToggle}
                          subMenuOff={this.subMenuOff}
                          subMenu={this.state.subMenu}
                          toolInit={this.toolInit}
                          toolOff={this.toolOff}
                          subOrigin={subOrigin}
                          subOff={this.subOff}
                          heightOn={this.heightOn}
                          heightOff={this.heightOff}
                          widthOn={this.widthOn}
                          secChange={this.secChange}
                          widthOff={this.widthOff}
                          typeOn={this.typeOn}
                          filterKey={this.filterKey}
                          getImg={this.getImg}
                          win={this.state.win}
                          chapter={chapter}
                          chapterChange={this.chapterChange}
                          toolReset={this.toolReset}
                        />
                      }
                    />
                    <Route
                      path='/'
                      element={
                        <Landing
                          initMap={this.initMap}
                          dragOn={this.dragOn}
                          dragOff={this.dragOff}
                          menu={this.state.menu}
                          subMenu={this.state.subMenu}
                          filterKey={this.filterKey}
                          animCount={this.animCount}
                          info={info}
                          typeOn={this.typeOn}
                          heightOff={this.heightOff}
                          secChange={this.secChange}
                          getImg={this.getImg}
                          win={this.state.win}
                          initUI={this.initUI}
                          ui={this.state.ui}
                          toolInit={this.toolInit}
                          toolOff={this.toolOff}
                          toolReset={this.toolReset}
                          count={this.state.count}
                        />
                      }
                    />
                  </Routes>
                </div>
              </div>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    )
  }
}

export default Main
