import { Component } from 'react'

import Main from './components/main/main'

class App extends Component {
  render() {
    return (
      <div className='App cNone dark'>
        <header className='App-header'></header>
        <Main />
      </div>
    )
  }
}

export default App
