// import { gql } from 'graphql-tag'
import { Component, createRef } from 'react'
import { withTranslation } from 'react-i18next'

import songFile from './test.mp3'
import { withRouter } from '../../utils'

class ThoughtsSingle extends Component {
  constructor() {
    super()
    this.target = null
    this.clickedDragger = false
    this.clickedSizer = false
    this.dragMode = ''
    this.x = 0
    this.y = 0

    this.audio = null
    this.canvas = createRef()
    this.ctx = ''
    this.x_end = 0
    this.y_end = 0
    this.bar_height = 0
    this.stop = false
    this.frameCount = 0
    this.fps = 5
    this.fpsInterval = 0
    this.startTime = 0
    this.now = 0
    this.then = 0
    this.elapsed = 0
    this.count = 0
    this.state = {
      audio: false,
      bar_width: 1,
      bars: 150,
      data: null,
      date: null,
      radius: 100,
    }
  }

  componentDidMount() {
    // eslint-disable-next-line no-console
    console.log(this.props)
    this.props.chapterChange(this.props.router.params.slug)
    this.getData()
    this.props.dragOn()
  }

  componentWillUnmount() {
    this.stopAnim()
    this.props.popReset()
    this.props.toolReset()
    this.props.chapterChange('')
    this.props.heightOff()
    this.props.dragOff()
  }

  getData() {
    fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            thought(filter: { opt3: "${this.props.router.params.slug}" }) {
              id
              title
              titleJa
              type
              opt1
              bodyJa
              opt2
              opt3
              media(filter: { rootPage: "thought" }) {
                name
                url
                type
              }
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then(res => res.json())
      .then(({ data }) =>
        this.setState({ data: data.thought }, () => {
          this.setState(
            { date: data.thought.title },
            // { date: this.props.prettyDate(this.state.data.created_at) },
            () => {
              if (this.state.data.type === 'audio') {
                this.setAudio()
                this.props.heightOff()
                window.scrollTo(0, 0)
              } else {
                this.props.heightOn()
                window.scrollTo(0, 0)
                this.props.popInit()
              }
            }
          )
        })
      )
  }

  setAudio = () => {
    const newFile = this.props.filterKey(this.state.data.media, 'type', 'audio')
    if (newFile.length) {
      this.audio = new Audio(songFile)
      this.audio.crossOrigin = 'anonymous'
      // this.audio.src = newFile[0].url;
      this.setState({ audio: true })
      this.audioInit()
    }
  }

  audioInit = () => {
    this.context = new (window.AudioContext || window.webkitAudioContext)()
    this.source = this.context.createMediaElementSource(this.audio)
    this.analyser = this.context.createAnalyser()
    this.fftSize = 4096
    this.source.connect(this.analyser)
    this.analyser.connect(this.context.destination)
    this.frequency_array = new Uint8Array(this.analyser.frequencyBinCount)
    this.LEN = this.frequency_array.length
    this.h = this.props.win[1] / this.LEN
    this.x = this.props.win[0] - 1
    this.canvasInit()
  }

  stopAnim = () => {
    this.setState({ audio: false }, () => {
      window.cancelAnimationFrame(this.rafId)

      if (this.audio !== null) {
        this.audio.pause()
        this.audio = null
      }
      this.rafId = null
      this.audio = null
      this.ctx = ''
      this.x_end = 0
      this.y_end = 0
      this.bar_height = 0
      this.stop = false
      this.frameCount = 0
      this.fps = 3
      this.fpsInterval = 0
      this.startTime = 0
      this.now = 0
      this.then = 0
      this.elapsed = 0
      this.count = 0
    })
  }

  togglePlay = () => {
    if (this.audio.paused) {
      this.fpsInterval = 1000 / 12
      this.then = Date.now()
      this.startTime = this.then
      this.audio.play()
    } else {
      this.audio.pause()
    }
  }

  canvasInit = () => {
    this.fpsInterval = 1000 / 9
    this.then = Date.now()
    this.startTime = this.then
    this.rafId = requestAnimationFrame(this.tick)
  }

  animationLooper(canvas) {
    if (canvas) {
      this.now = Date.now()
      this.elapsed = this.now - this.then
      if (this.elapsed > this.fpsInterval) {
        canvas.width = this.props.win[0]
        canvas.height = this.props.win[1]
        this.ctx = canvas.getContext('2d')
        this.then = this.now - (this.elapsed % this.fpsInterval)

        for (let i = 0; i < this.state.bars; i++) {
          // divide a circle into equal part
          const rads = (Math.PI * 2) / this.state.bars

          // Math is magical
          this.bar_height = this.frequency_array[i] * 1.5

          const x =
            this.props.win[0] / 2 +
            Math.cos(rads * i + this.count / 360) * this.state.radius
          const y =
            this.props.win[1] / 2 +
            Math.sin(rads * i + this.count / 360) * this.state.radius
          this.x_end =
            this.props.win[0] / 2 +
            Math.cos(rads * i + this.count / 360) *
              (this.state.radius + this.bar_height)
          this.y_end =
            this.props.win[1] / 2 +
            Math.sin(rads * i + this.count / 360) *
              (this.state.radius + this.bar_height)

          // draw a bar

          this.drawBar(
            x,
            y,
            this.x_end,
            this.y_end,
            this.frequency_array[i],
            this.ctx,
            canvas
          )
          this.count++
        }
      }
      //   let imgData = this.ctx.getImageData(1, 0, this.props.win[0] - 1, this.props.win[1]);
      //   this.ctx.putImageData(imgData, 0, 0);
      //   this.analyser.getByteFrequencyData(this.frequency_array);
      //   for (let i = 0; i < this.LEN; i++) {
      //     let rat = this.frequency_array[i] / 255;
      //     let hue = Math.round((rat * 120) + 280 % 360);
      //     let sat = '100%';
      //     let lit = 10 + (70 * rat) + '%';
      //     this.ctx.lineWidth = 10;
      //     this.ctx.beginPath();
      //     this.ctx.strokeStyle = `hsl(${hue}, ${sat}, ${lit})`;
      //     this.ctx.moveTo(this.x, this.props.win[1] - (i * this.h));
      //     this.ctx.lineTo(this.x, this.props.win[1] - (i * this.h + this.h));
      //     this.ctx.stroke();
      // }
      // console.log(this.x)
    } else {
      this.stopAnim()
    }
  }

  drawBar(x1 = 0, y1 = 0, x2 = 0, y2 = 0, _frequency, ctx, _canvas) {
    const lineColor = 'rgb(255,255,255)'
    ctx.strokeStyle = lineColor
    ctx.lineWidth = this.state.bar_width
    ctx.beginPath()
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.stroke()
  }

  tick = () => {
    if (this.state.audio) {
      this.animationLooper(this.canvas.current)
      this.analyser.getByteTimeDomainData(this.frequency_array)
      this.rafId = requestAnimationFrame(this.tick)
    }
  }

  render() {
    const { data, audio /* date */ } = this.state
    const { win } = this.props
    return (
      <>
        {data && true /* date */ ? (
          <>
            <div className='titleHold eNone'>
              <p className='onNorm tWhite tFooter'>
                {/* {this.props.typeOn(date, 0)}:{' '} */}
                {this.props.typeOn(data.title, 0.2)}
              </p>
            </div>
            <div
              className='thoughtSingleHold fullWidth ptHuge'
              key={`${data.title}-${data.id}`}
            >
              <div className='fullStage eCenter colGut relative'></div>
              {audio ? (
                <>
                  <div
                    className='fullWidth fullHeight eNone butHold eLT'
                    key={`${data.title}-${data.id}`}
                  >
                    <div
                      className='playBut centeredContent eFill blinkFast'
                      data-tool='play/pause'
                      onMouseEnter={this.props.toolInit}
                      onMouseOut={this.props.toolOff}
                      onClick={this.togglePlay}
                    >
                      <img
                        className='imgFull eNone'
                        src='/images/play.svg'
                      ></img>
                    </div>
                  </div>
                  <div
                    className='canvasHold eLT eNone fadeOn'
                    style={{ height: win[1], width: win[0] }}
                    onClick={this.togglePlay}
                  >
                    <canvas
                      ref={this.canvas}
                      style={{ height: '100%', width: '100%' }}
                    ></canvas>
                  </div>
                </>
              ) : (
                <div
                  className='fullStage quill eCenter thoughtHold tWhite colGut pbHuge fadeOn'
                  dangerouslySetInnerHTML={{
                    __html:
                      this.props.i18n.language === 'ja'
                        ? data.bodyJa
                        : data.opt1,
                  }}
                ></div>
              )}
            </div>
          </>
        ) : (
          ''
        )}
      </>
    )
  }
}

export default withTranslation()(withRouter(ThoughtsSingle))
