import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import { useInterval } from 'react-use'

import { ConvoMessage } from './convo-message'
import type { Emoji, Message } from './types'
import './convo.css'

export function Convo({
  toolInit,
  toolOff,
}: {
  toolInit: (args: any) => any
  toolOff: (args: any) => any
}) {
  const [convo, setConvo] = useState<any>()
  const [emojis, setEmojis] = useState<Emoji[]>([])
  const [messageIndex, setMessageIndex] = useState<number | null>(null)
  const [typing, setTyping] = useState(false)
  const [typingIndex, setTypingIndex] = useState(0)

  useInterval(() => setTypingIndex((typingIndex + 1) % 6), 100)

  const { id } = useParams()

  const getConvo = async () => {
    const res = await fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            convo(filter: { id: "${id}" }) {
              id
              title
              contact: opt1
              messages: options {
                id
                from: title
                html: opt1
                emoji: opt2
                order
                rootpage
              }
            }
            emojis: medias(filter: { rootPage: "convos", rootID: 0 }) {
              name: section
              src: url
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
    const { data: { convo = null, emojis = [] } = {} } = await res.json()
    setConvo(
      convo
        ? {
            ...convo,
            messages: convo.messages
              ?.filter?.((x: any) => x.rootpage === 'convos')
              .sort(
                ({ order: a }: any, { order: b }: any) => a - b
              ) as Message[],
          }
        : convo
    )
    setEmojis(emojis)
  }

  useEffect(() => {
    getConvo()
  }, [id])

  useEffect(() => {
    if (convo && messageIndex === null) setTyping(true)
  }, [convo])

  // const { width } = useWindowSize()

  return (
    <div className='h-full overflow-y-scroll scrollbar-hide'>
      <div className='sticky top-0 z-1 backdrop-filter backdrop-blur backdrop-blur-sm border-b border-solid border-current p-4 font-mono tracking-wider flex items-center space-x-4 '>
        {/* {width < 640 && (
          <Link
            to='/convos'
            className='all:block -m-1'
            dangerouslySetInnerHTML={{
              __html: IconArrowLeft as unknown as string,
            }}
            onClick={e => (isMobile ? false : toolOff(e))}
            onMouseEnter={e => (isMobile ? false : toolInit(e))}
            onMouseLeave={e => (isMobile ? false : toolOff(e))}
            data-status='false'
            data-tool={`RUN-02: Conversation logs`}
            data-off={'conversation log is active'}
          />
        )} */}
        <span className='flex-auto'>{convo?.title}</span>
      </div>
      <ul className='flex flex-col !flex-nowrap !justify-start space-y-8 py-8 px-4'>
        {convo?.messages?.map((x: any, i: number, arr: any[]) => (
          <CSSTransition
            key={x.id}
            classNames='baz'
            in={messageIndex != null && i <= messageIndex}
            timeout={2000}
            unmountOnExit
            onEntering={(node: Element) =>
              node.scrollIntoView({ behavior: 'smooth' })
            }
            onEntered={() => {
              // if ((messageIndex ?? 0) < arr.length - 1)
              //   setTimeout(() => setTyping(true), x.html.length * 5)
            }}
          >
            <li
              className={`transition-transform ${
                x.from === 'z'
                  ? 'self-end origin-top-right ml-16'
                  : 'self-start origin-top-left mr-16'
              }`}
            >
              <ConvoMessage
                {...{ emojis, toolInit, toolOff }}
                className='!duration-2000 text-left'
                message={x}
                unread={i === messageIndex}
                onRead={() => {
                  if ((messageIndex ?? 0) < arr.length - 1)
                    setTimeout(
                      () => setTyping(true),
                      x.html.length * 5 + (x.html.includes('sup') ? 300 : 1000)
                    )
                  else if ((messageIndex ?? 0) === arr.length - 1)
                    setMessageIndex((messageIndex ?? 0) + 1)
                }}
              />
            </li>
          </CSSTransition>
        ))}
        <CSSTransition
          key={convo?.messages?.[messageIndex ?? 0]?.id ?? 'initial'}
          classNames='baz'
          exit={false}
          in={typing}
          timeout={
            (convo?.messages[
              Math.min((messageIndex ?? -1) + 1, convo.messages.length - 1)
            ]?.html?.length * 10 ?? 1000) + 1000
          }
          unmountOnExit
          onEntering={(node: Element) =>
            node.scrollIntoView({ behavior: 'smooth' })
          }
          onEntered={() => {
            setTyping(false)
            setMessageIndex(
              messageIndex === null
                ? 0
                : Math.min(messageIndex + 1, convo.messages.length - 1)
            )
          }}
        >
          <p
            className={`transition-transform ${
              convo?.messages[
                Math.min((messageIndex ?? -1) + 1, convo.messages.length - 1)
              ]?.from === 'z'
                ? 'text-right origin-top-right ml-8'
                : 'text-left origin-top-left mr-8'
            }`}
          >
            {new Array(3).fill('.').map((x, i) => (
              <span
                className={`inline-block transition-transform transform ${
                  i === typingIndex ? '-translate-y-0.25' : 'translate-y-0'
                }`}
                key={i}
              >
                {x}
              </span>
            ))}
          </p>
        </CSSTransition>
      </ul>
    </div>
  )
}
