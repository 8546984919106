import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect'

import type { Emoji, Message } from './types'
import { Audio } from '../audio'

import './convo-message.css'

export function ConvoMessage({
  className = '',
  emojis,
  message: { emoji: _emoji, html },
  onRead,
  toolInit,
  toolOff,
  unread = false,
  ...attrs
}: React.HTMLAttributes<HTMLDivElement> & {
  emojis: Emoji[]
  message: Message
  onRead: () => void
  toolInit: (args: any) => any
  toolOff: (args: any) => any
  unread?: boolean
}) {
  const emoji = emojis?.find(x => x.name === _emoji)

  const [read, setRead] = useState(false)
  useEffect(() => {
    if (read) onRead()
  }, [read])

  const [attachments, setAttachments] = useState<Record<string, string>[]>([])
  const [attachmentsIndex, setAttachmentsIndex] = useState(0)
  const onAttachmentDone = (i: number) => {
    if (i <= attachmentsIndex) setAttachmentsIndex(attachmentsIndex + 1)
    if (i === attachments.length - 1) setRead(true)
  }

  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    ref.current?.querySelectorAll('[data-tool]').forEach(el => {
      el.addEventListener('mouseenter', e => (isMobile ? false : toolInit(e)))
      el.addEventListener('mouseleave', e => (isMobile ? false : toolOff(e)))
    })
  }, [ref.current])

  useLayoutEffect(() => {
    if (!attachments.length) {
      const _attachments = Array.from(
        ref.current?.querySelectorAll('sup[data-type]') ?? [],
        el =>
          [
            Array.from(el.attributes).reduce(
              (acc, { name, value }) => ({
                ...acc,
                [name]: value,
              }),
              {} as Record<string, string>
            ),
            () => el.remove(),
          ] as const
      )
        .filter(([attrs]) =>
          ['audioLink', 'imageLink', 'videoLink'].includes(attrs['data-type'])
        )
        .reduce((acc, [attrs, remove]) => {
          remove()
          return [...acc, attrs]
        }, [] as Record<string, string>[])

      if (_attachments.length) setAttachments(_attachments)
      else setRead(true)
    }
  }, [ref.current])

  return (
    <div
      {...attrs}
      ref={ref}
      className={`app-convo-message transform transition-all ${
        unread ? 'animate-pulse' : ''
      } ${className}`}
    >
      {!!html && (
        <div className='inline-block relative'>
          <div
            className='bg-dots bg-left-top p-5 -mx-4 font-serif text-base leading-tight all:inline'
            dangerouslySetInnerHTML={{ __html: html }}
          />
          {!!emoji && (
            <video
              autoPlay
              className='bg-dots p-4 app-convo-message-emoji absolute z-1 -right-15 -bottom-3.5 z-1 w-16 aspect-square object-cover'
              loop
              muted
              src={emoji.src}
              style={{ aspectRatio: '1 / 1' }}
            />
          )}
        </div>
      )}

      {attachments.map(
        ({ 'data-src': src, 'data-type': type }, i) =>
          i <= attachmentsIndex &&
          (
            {
              audioLink: () => (
                <Audio
                  className='w-64 p-4 bg-dots'
                  key={src}
                  autoPlay
                  src={src}
                  onPause={() => onAttachmentDone(i)}
                  onEnded={() => onAttachmentDone(i)}
                />
              ),
              imageLink: () => (
                <img
                  key={src}
                  className='-mt-2 -z-1 max-w-full max-h-40 p-4 bg-dots'
                  src={src}
                  onLoad={() => onAttachmentDone(i)}
                />
              ),
              videoLink: () => (
                <iframe
                  key={src}
                  className='-mt-2 w-64 max-h-40 p-4 bg-dots'
                  src={`https://www.youtube.com/embed/${src}?autoplay=${
                    attachments.findIndex(x => x.type === 'videoLink') === i
                      ? 1
                      : 0
                  }`}
                  frameBorder='0'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                  allowFullScreen
                  onLoad={() => onAttachmentDone(i)}
                ></iframe>
              ),
            } as Record<string, () => JSX.Element>
          )[type]?.()
      )}
    </div>
  )
}
