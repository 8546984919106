// import { gql } from 'graphql-tag'
import { Component } from 'react'

// import { Link, Route, Redirect } from 'react-router-dom'
import './about.css'

class About extends Component {
  constructor() {
    super()
    this.x = 0
    this.y = 0
    this.state = {
      data: [],
      edu: [],
      job: [],
      link: [],
      press: [],
    }
  }

  componentDidMount() {
    this.props.secChange('about')
    this.getData()
    window.scrollTo(0, 0)
    this.props.heightOn()
    this.props.dragOn()
    this.props.initMap(4, 0)
  }

  componentWillUnmount() {
    this.props.heightOff()
    this.props.toolReset()
    this.props.popReset()
    this.props.dragOff()
  }

  getData = () => {
    let hasSetData = false
    const setData = ({ about }) => {
      this.setState({ data: about }, () => {
        this.filterOpts()
        if (!hasSetData) {
          hasSetData = true
          this.props.popInit()
        }
      })
    }

    const _data = window.localStorage.getItem('about-data')
    if (_data && _data !== 'undefined') setData(JSON.parse(_data) ?? {})

    fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            about {
              bio
              media(filter: { rootPage: "about" }) {
                name
                section
                url
              }
              options(filter: { rootpage: "about" }) {
                title
                type
                opt1
                opt2
                opt3
                opt4
                opt5
              }
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then(res => res.json())
      .then(data => {
        window.localStorage.setItem('about-data', JSON.stringify(data.data))
        setData(data.data)
      })
  }

  filterOpts = () => {
    const job = this.props.filterKey(this.state.data.options, 'type', 'job')
    const edu = this.props.filterKey(
      this.state.data.options,
      'type',
      'education'
    )
    const press = this.props.filterKey(this.state.data.options, 'type', 'press')
    const link = this.props.filterKey(this.state.data.options, 'type', 'link')
    this.setState({ edu, job, link, press })
  }

  render() {
    const { data, job, edu, press, link } = this.state
    return data && data.media && data.bio ? (
      <>
        <div className='contentHolder fullWidth pStart pbHuge'>
          <div className='bioHold fullStage eCenter clearBoth mbHuge colGut'>
            {data.media.find(x => x.section === 'profile') ? (
              <div className='col4 floatL mrSm ptXs fadeOn aboutPortrait'>
                <div className='fullWidth relative'>
                  <div className='fullWidth rPortrait'></div>
                  <img
                    className='imgCover eLT'
                    src={this.props.getImg(
                      data.media.find(x => x.section === 'profile').name,
                      1000
                    )}
                  ></img>
                </div>
              </div>
            ) : (
              ''
            )}
            <div
              className='tWhite tUpper quill fadeOn'
              style={{ animationDelay: '.25s' }}
              dangerouslySetInnerHTML={{ __html: data.bio }}
            ></div>
          </div>
          <div className='fullStage flex eCenter colGut'>
            <div className='cvHold col7'>
              <div className='jobsHold fullWidth tWhite mbLrg'>
                <p className='tSub tUpper mbXs blinkOn'>Experience</p>
                {job.length
                  ? job.map((item, i) => {
                      return (
                        <div className='fullWidth mbLrg' key={`job-${i}`}>
                          <div className='fullWidth flex mbXs'>
                            <a
                              href={item.opt1}
                              target='blank'
                              className='eFill col3 aboutOptTitle'
                              data-tool='RUN EXT PROTOCOL'
                              onMouseEnter={this.props.toolInit}
                              onMouseOut={this.props.toolOff}
                            >
                              <div className='fullWidth eNone'>
                                <p className='tFooter onNorm jobName '>
                                  {this.props.typeOn(item.title, i * 0.05)}
                                </p>
                              </div>
                            </a>

                            <div className='col2 aboutOptDet aboutLine'>
                              <p className='tFooter'>{item.opt5}</p>
                            </div>

                            <div className='col2 aboutOptDet'>
                              <p className='tFooter'>{item.opt2}</p>
                            </div>
                          </div>
                          <div className='fullWidth plXs col5 aboutOptDes'>
                            <p className='tFooter'>{item.opt3}</p>
                          </div>
                          {!!(item.opt4?.length > 11) && (
                            <div
                              className='fullWidth plXs mtXs tFooter'
                              dangerouslySetInnerHTML={{ __html: item.opt4 }}
                            />
                          )}
                        </div>
                      )
                    })
                  : ''}
              </div>
              <div className='eduHold fullWidth tWhite mbLrg'>
                <p className='blinkOn tSub tUpper mbXs'>Education</p>
                {edu.length
                  ? edu.map((item, i) => {
                      return (
                        <div className='fullWidth mbLrg' key={`edu-${i}`}>
                          <div className='fullWidth flex mbXs'>
                            <div className='col3 aboutOptTitle'>
                              <p className='tFooter'>{item.title}</p>
                            </div>
                            <div className='col2  aboutOptDet  aboutLine'>
                              <p className='tFooter'>{item.opt1}</p>
                            </div>

                            <div className='col2 aboutOptDet'>
                              <p className='tFooter'>{item.opt2}</p>
                            </div>
                          </div>
                          <div className='fullWidth plXs col5 aboutOptDes'>
                            <p className='tFooter'>{item.opt3}</p>
                          </div>
                        </div>
                      )
                    })
                  : ''}
              </div>
            </div>
            <div className='cvHold col2'>
              {!!press?.filter(x => x.title && x.opt1).length && (
                <div className='pressHold fullWidth tWhite mbLrg'>
                  <p className='tSub tUpper mbXs blinkOn'>Press</p>
                  {press
                    .filter(x => x.title && x.opt1)
                    .map((x, i) => (
                      <a
                        href={x.opt1}
                        key={`press-${x.opt1}`}
                        target='blank'
                        className='eFill toggleTypeOn'
                        data-tool='RUN EXT PROTOCOL'
                        onMouseEnter={this.props.toolInit}
                        onMouseOut={this.props.toolOff}
                      >
                        <div className='fullWidth flex pbXs eNone'>
                          <p className='tFooter eNone onNorm'>
                            {this.props.typeOn(x.title, i * 0.05)}
                          </p>
                        </div>
                      </a>
                    ))}
                </div>
              )}
              {!!link?.filter(x => x.title && x.opt1).length && (
                <div className='linkHold fullWidth tWhite mbMed'>
                  <p className='tSub tUpper mbXs blinkOn '>Links</p>
                  {link
                    .filter(x => x.title && x.opt1)
                    .map((x, i) => (
                      <a
                        href={x.opt1}
                        key={`link-${x.opt1}`}
                        target='blank'
                        className='eFill toggleTypeOn'
                        data-tool='RUN EXT PROTOCOL'
                        onMouseEnter={this.props.toolInit}
                        onMouseOut={this.props.toolOff}
                      >
                        <div className='fullWidth flex pbXs eNone onNorm'>
                          <p className='tFooter eNone onNorm'>
                            {this.props.typeOn(x.title, i * 0.05)}
                          </p>
                        </div>
                      </a>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    ) : (
      ''
    )
  }
}

export default About
