export function TmpPause(props: any) {
  return (
    <svg
      className='inline-block'
      preserveAspectRatio='xMidYMid meet'
      viewBox='0 0 24 24'
      width='1.2em'
      height='1.2em'
      {...props}
    >
      <path fill='currentColor' d='M14 19h4V5h-4M6 19h4V5H6v14Z' />
    </svg>
  )
}
