import classNames from 'classnames'
import { useAudio } from 'react-use'
import type { SetRequired } from 'type-fest'

import { TmpPause, TmpPlay } from '../components/icons-tmp'

export function Audio({
  className,
  ...attrs
}: SetRequired<React.AudioHTMLAttributes<HTMLAudioElement>, 'src'>) {
  const [audio, { time, duration, playing }, { play, pause, seek }] = useAudio({
    ...attrs,
    hidden: true,
  })

  return (
    <div className={classNames('flex items-center space-x-4', className)}>
      {audio}
      {playing ? (
        <TmpPause onClick={() => pause()} />
      ) : (
        <TmpPlay onClick={() => play()} />
      )}
      <div
        className='flex-auto h-1.5 border border-solid border-current'
        onClick={({ clientX, currentTarget }) => {
          const { left } = currentTarget.getBoundingClientRect()
          const percent = (clientX - left) / currentTarget.offsetWidth
          seek(percent * duration)
        }}
      >
        <div
          className='h-full bg-current'
          style={{ width: `${(time / duration) * 100}%` }}
        />
      </div>
      <span
        className='font-mono'
        style={{ fontVariantNumeric: 'tabular-nums' }}
      >
        <span className='inline-block w-7 text-center'>
          {Math.floor(time / 60)
            .toString()
            .padStart(1, '0')}
          :
          {Math.floor(time % 60)
            .toString()
            .padStart(2, '0')}
        </span>
        <span className='opacity-50'>/</span>
        <span className='inline-block w-7 text-center opacity-50'>
          {Math.floor(duration / 60)
            .toString()
            .padStart(1, '0')}
          :
          {Math.floor(duration % 60)
            .toString()
            .padStart(2, '0')}
        </span>
      </span>
    </div>
  )
}
