// import { gql } from 'graphql-tag'
import { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'

class Hub extends Component {
  constructor() {
    super()
    this.state = {
      about: [],
      col: [],
      convo: [],
      emoji: [],
      grid: false,
      landing: [],
      mAbout: [],
      mBlog: [],
      mConvo: [],
      pos: false,
      random: [],
      thoughts: null,
      thoughtsDate: '',
      work: [],
    }
  }

  componentDidMount() {
    this.getData()
    this.props.dragOn()
  }

  componentWillUnmount() {
    this.props.dragOff?.()
    this.setState({ grid: false })
    window.removeEventListener('resize', this.runGrid, false)
  }

  getData = () => {
    fetch('/api/graphql', {
      body: JSON.stringify({
        query: /* gql */ `
          query {
            works {
              id
              title
              opt3
              opt1
              opt2
              media(filter: { rootPage: "work", section: "cover" }) {
                name
                url
                type
              }
              options(filter: { rootpage: "work", type: "initiative" }) {
                title
                opt2
              }
            }
            medias(filter: { rootPage: "finish" }) {
              url
              name
              section
              type
            }
            convos {
              id
              title
              opt1

              opt7
              opt8
              media(filter: { rootPage: "convos" }) {
                name
                url
                section
                type
              }
            }
            thought {
              id
              title
              type
              opt2
              opt3
            }
          }
        `,
      }),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    })
      .then(res => res.json())
      .then(data =>
        this.setState(
          {
            convo: data.data.convos,
            landing: data.data.medias,
            thoughts: data.data.thought,
            work: data.data.works,
          },
          () => {
            window.addEventListener('resize', this.runGrid, false)
            const mAbout = this.props.filterKey(
              this.state.landing,
              'section',
              'aboutlogo'
            )
            const mConvo = this.props.filterKey(
              this.state.landing,
              'section',
              'convo'
            )
            const mJour = this.props.filterKey(
              this.state.landing,
              'section',
              'thought'
            )
            const mBlog = this.props.filterKey(
              this.state.landing,
              'section',
              'blog'
            )
            this.setState(
              {
                mAbout,
                mBlog,
                mConvo,
                mJour,
                // thoughtsDate: this.prettyDate(this.state.thoughts.created_at),
              },
              () => {
                // this.randomPos();
                this.setWork()
                this.runGrid()
              }
            )
          }
        )
      )
  }

  setWork = () => {
    const curr = this.random(this.state.work.length)
    const rand = [...this.state.random]
    rand[0] = curr
    this.setState({ random: rand }, () => {
      this.setConvo()
    })
  }

  setConvo = () => {
    const curr = this.random(this.state.convo.length)
    const rand = [...this.state.random]
    rand[1] = curr
    this.setState({ random: rand }, () => {
      let emoji = this.props.filterKey(
        this.state.convo[this.state.random[1]].media,
        'section',
        'laugh'
      )
      if (!emoji.length) {
        emoji = this.props.filterKey(
          this.state.convo[this.state.random[1]].media,
          'section',
          'default'
        )
      }
      if (!emoji.length) {
        emoji = this.props.filterKey(
          this.state.convo[this.state.random[1]].media,
          'section',
          'cover'
        )
      }
      this.setState({ emoji }, () => {
        this.randomPos()
      })
    })
  }

  runGrid = () => {
    this.setState({ col: [], grid: false }, () => {
      if (this.props.win[0] < this.props.win[1]) {
        this.runHeight()
      } else {
        this.runWidth()
      }
    })
  }

  runWidth = () => {
    if (!this.state.grid) {
      this.setState({ grid: true }, () => {
        const full = []
        const count = 12
        const col = this.props.win[0] / count
        const height = Math.ceil(this.props.win[1] / col)
        const remainder = (col - (this.props.win[1] - (col * height - 1))) / 2
        for (let i = 0; i <= count; i++) {
          full.push(
            <div
              className='gridCol growHeight'
              key={`gridcol-${i}`}
              style={{ animationDelay: `${i * 0.25}s`, left: col * i }}
            ></div>
          )
        }
        for (let e = 0; e <= height; e++) {
          full.push(
            <div
              className='gridRow growWidth'
              key={`gridrow-${e}`}
              style={{
                animationDelay: `${e * 0.25}s`,
                top: col * e - remainder,
              }}
            ></div>
          )
        }
        this.setState({ col: full })
      })
    }
  }

  runHeight = () => {
    if (!this.state.grid) {
      this.setState({ grid: true }, () => {
        const full = []
        const count = 4
        const col = this.props.win[0] / count
        const height = Math.ceil(this.props.win[1] / col)
        const remainder = (col - (this.props.win[1] - (col * height - 1))) / 2
        for (let i = 0; i <= count; i++) {
          full.push(
            <div
              className='gridCol growHeight'
              style={{ animationDelay: `${i * 0.25}s`, left: col * i }}
            ></div>
          )
        }
        for (let e = 0; e <= height; e++) {
          full.push(
            <div
              className='gridRow growWidth'
              style={{
                animationDelay: `${e * 0.25}s`,
                top: col * e - remainder,
              }}
            ></div>
          )
        }
        this.setState({ col: full })
      })
    }
  }

  prettyDate = curr => {
    const t = new Date(parseInt(curr))

    return `${t.getFullYear()}${(t.getMonth() + 1)
      .toString()
      .padStart(2, '0')}${t.getDate().toString().padStart(2, '0')}`
  }

  random = num => {
    const curr = Math.ceil(Math.random() * num) - 1
    return curr
  }

  randomPos = (/* e */) => {
    if (!this.state.pos) {
      this.setState({ pos: true }, () => {
        const el = document.getElementsByClassName('singleConsole')
        if (el.length) {
          Array.prototype.forEach.call(el, function (item) {
            const grid = parseInt(item.getAttribute('data-grid'))
            let left = 0
            let top = 0
            if (window.innerWidth > 800) {
              if (grid === 1) {
                left =
                  Math.random() * (window.innerWidth / 2 - item.offsetWidth)
                top =
                  Math.random() * (window.innerHeight / 2 - item.offsetHeight) +
                  window.innerHeight * 0.05
              }
              if (grid === 2) {
                left =
                  Math.random() * (window.innerWidth / 2 - item.offsetWidth)
                top =
                  Math.random() * (window.innerHeight / 2 - item.offsetHeight) +
                  window.innerHeight * 0.4
              }
              if (grid === 3) {
                left =
                  Math.random() * (window.innerWidth / 2 - item.offsetWidth) +
                  window.innerWidth / 2
                top =
                  Math.random() * (window.innerHeight / 2 - item.offsetHeight) +
                  window.innerHeight * 0.05
              }
              if (grid === 4) {
                left =
                  Math.random() * (window.innerWidth / 2 - item.offsetWidth) +
                  window.innerWidth / 2
                top =
                  Math.random() * (window.innerHeight / 2 - item.offsetHeight) +
                  window.innerHeight * 0.4
              }
            } else {
              if (grid === 1) {
                left = Math.random() * (window.innerWidth - item.offsetWidth)
                top =
                  Math.random() * (window.innerHeight / 2 - item.offsetHeight) +
                  window.innerHeight * 0.05
              }
              if (grid === 2) {
                left =
                  Math.random() * (window.innerWidth * 0.75 - item.offsetWidth)
                top =
                  Math.random() * (window.innerHeight / 2 - item.offsetHeight) +
                  window.innerHeight * 0.4
              }
              if (grid === 3) {
                left =
                  Math.random() *
                    (window.innerWidth * 0.85 - item.offsetWidth) +
                  window.innerWidth * 0.15
                top =
                  Math.random() * (window.innerHeight / 2 - item.offsetHeight) +
                  window.innerHeight * 0.05
              }
              if (grid === 4) {
                left =
                  Math.random() * (window.innerWidth * 0.8 - item.offsetWidth) +
                  window.innerWidth * 0.2
                top =
                  Math.random() * (window.innerHeight / 2 - item.offsetHeight) +
                  window.innerHeight * 0.4
              }
            }
            item.style.left = `${left}px`
            item.style.top = `${top}px`
          })
        }
      })
    }
  }

  render() {
    const {
      // grid,
      // work,
      col,
      random,
      mAbout,
      // mBlog,
      // convo,
      // emoji,
      // thoughts,
    } = this.state
    const { win, menu, subMenu /* info */ } = this.props

    return (
      <>
        <div
          className='gridHold fullWidth fullHeight eNone'
          key={`hub-${win[0]}-${win[1]}`}
        >
          {col.length ? col.map(x => x) : ''}
        </div>
        <div
          className={`consoleHold fullWidth fullHeight eNone ${
            menu || subMenu ? 'hide' : ''
          }`}
        >
          {/* work */}
          {/* {work.length ? (
            <div
              className='fadeOn singleConsole draggable eFill consoleWork'
              data-grid='1'
              style={{ animationDelay: '4s', zIndex: 0 }}
              key={'consoleWork'}
            >
              <div className='fullWidth relative eNone'>
                <div
                  className='sizer active eFill'
                  data-tool='resize'
                  onMouseEnter={this.props.toolInit}
                  onMouseOut={this.props.toolOff}
                ></div>
                <div className='popStroke bWhite bSm bgBlack eNone'></div>
                {random.length ? (
                  <>
                    <Link to={`/work/${work[random[0]].opt3}`}>
                      <div
                        className='ptSm relative consoleTitle plSm prSm eFill'
                        data-tool=' '
                        onMouseEnter={this.props.toolInit}
                        onMouseOut={this.props.toolOff}
                      >
                        <p className='onNorm tSub tUpper tWhite eNone'>
                          {this.props.typeOn(`${work[random[0]].title}`, 4.5)}
                          <sup>
                            {this.props.typeOn(`RUN Featured Project`, 4.9)}
                          </sup>
                        </p>
                      </div>
                    </Link>
                    <div className='rWide imageHold eNone'>
                      <div className='dragger eFill dragBuff fullHeight'></div>

                      <Fragment key={`work-${random[0]}`}>
                        {work[random[0]].media[0]?.type === 'images' ? (
                          <img
                            className='imgCover'
                            src={this.props.getImg(
                              work[random[0]].media[0].name,
                              1400
                            )}
                          ></img>
                        ) : (
                          <video
                            className='imgCover'
                            autoPlay
                            loop
                            playsInline
                            muted
                            preload='auto'
                          >
                            <source
                              src={work[random[0]].media[0]?.url}
                            ></source>
                          </video>
                        )}
                      </Fragment>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            ''
          )} */}

          {/* About */}
          {mAbout.length ? (
            <div
              className='fadeOn singleConsole draggable eFill consoleAbout'
              data-grid='3'
              style={{ animationDelay: '4.5s', left: 0, right: 0, zIndex: 3 }}
            >
              <div className='fullWidth relative eNone'>
                <div
                  className='sizer active eFill'
                  data-tool='resize'
                  onMouseEnter={this.props.toolInit}
                  onMouseOut={this.props.toolOff}
                ></div>
                <div className='popStroke bWhite bSm bgBlack eNone'></div>
                {random.length ? (
                  <>
                    <div className='rSquared eNone'>
                      <div className='fullWidth fullHeight eLT'>
                        <div className='imageHold fullWidth fullHeight'>
                          <Link to={`/about`}>
                            <div
                              className='ptXs relative consoleTitle plSm prSm eLT eFill'
                              data-tool=' '
                              onMouseEnter={this.props.toolInit}
                              onMouseOut={this.props.toolOff}
                            >
                              <p className='onNorm tSub tUpper tWhite eNone'>
                                {this.props.typeOn(`PROFILE Z`, 4.5)}
                                <sup>{this.props.typeOn(`RUN About`, 4.9)}</sup>
                              </p>
                            </div>
                          </Link>
                          <div className='dragger eFill dragBuff fullHeight'></div>

                          <Fragment key={`about`}>
                            {mAbout[0].type === 'images' ? (
                              <img
                                className='imgCover'
                                src={this.props.getImg(mAbout[0].name, 1400)}
                              ></img>
                            ) : (
                              <video
                                className='imgCover'
                                autoPlay
                                loop
                                playsInline
                                muted
                                preload='auto'
                              >
                                <source src={mAbout[0].url}></source>
                              </video>
                            )}
                          </Fragment>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            ''
          )}

          {/* {convo.length ? (
            <div
              className='fadeOn singleConsole draggable eFill consoleConvo'
              data-grid='2'
              style={{ animationDelay: '5s', zIndex: 1 }}
              key={'consoleConvo'}
            >
              <div className='fullWidth relative eNone'>
                <div
                  className='sizer active eFill'
                  data-tool='resize'
                  onMouseEnter={this.props.toolInit}
                  onMouseOut={this.props.toolOff}
                ></div>
                <div className='popStroke bWhite bSm bgBlack eNone'></div>
                {random.length > 1 ? (
                  <>
                    <Link to={`/convo/${convo[random[1]].opt8}`}>
                      <div
                        className='ptSm relative consoleTitle plSm prSm eFill'
                        data-tool=' '
                        onMouseEnter={this.props.toolInit}
                        onMouseOut={this.props.toolOff}
                      >
                        <p className='onNorm tSub tUpper tWhite eNone'>
                          {this.props.typeOn(`INCOMING MESSAGE`, 5.4)}
                          <sup>
                            <br />
                            {this.props.typeOn(`RUN CONVO`, 5.7)}
                          </sup>
                        </p>
                      </div>
                    </Link>
                    <div className='rSquared eNone relative'>
                      <div className='dragger eFill dragBuff fullHeight'></div>

                      <div className='fullWidth fullHeight eLT'>
                        <div className='imageHold fullWidth fullHeight'>
                          {emoji.length ? (
                            <Fragment key={`convo-${random[1]}`}>
                              {emoji[0].type === 'images' ? (
                                <img
                                  className='imgCover'
                                  src={this.props.getImg(emoji[0].name, 1400)}
                                ></img>
                              ) : (
                                <video
                                  className='imgCover'
                                  autoPlay
                                  loop
                                  playsInline
                                  muted
                                  preload='auto'
                                >
                                  <source src={emoji[0].url}></source>
                                </video>
                              )}
                            </Fragment>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='pbSm relative consoleTitle plSm prSm'>
                      <p className='onNorm tFooter tUpper tWhite eNone'>
                        {this.props.typeOn(
                          `FROM ${convo[random[1]].opt1}`,
                          5.9
                        )}
                      </p>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            ''
          )} */}

          {/* {thoughts ? (
            <div
              className='fadeOn singleConsole draggable eFill consoleThoughts'
              data-grid='4'
              style={{ animationDelay: '5.25s', left: 0, right: 0, zIndex: 2 }}
            >
              <div className='fullWidth relative eNone'>
                <div
                  className='sizer active eFill'
                  data-tool='resize'
                  onMouseEnter={this.props.toolInit}
                  onMouseOut={this.props.toolOff}
                ></div>
                <div className='popStroke bWhite bSm bgBlack eNone'></div>
                {random.length ? (
                  <>
                    <Link to={`/thoughts/${thoughts.opt3}`}>
                      <div
                        className='ptSm relative consoleTitle plSm prSm eFill'
                        data-tool=' '
                        onMouseEnter={this.props.toolInit}
                        onMouseOut={this.props.toolOff}
                      >
                        <p className='onNorm tSub tUpper tWhite eNone'>
                          {this.props.typeOn(`${thoughts.title}`, 6.4)}
                          <sup>
                            {this.props.typeOn(`RUN JOURNAL ENTRY`, 5.5)}
                          </sup>
                        </p>
                      </div>
                    </Link>
                    <div className='r34 relative eNone'>
                      <div className='fullWidth fullHeight eLT'>
                        <div className='imageHold fullWidth fullHeight'>
                          <div className='dragger eFill dragBuff fullHeight'></div>

                          <Fragment key={`about`}>
                            {mBlog[0].type === 'images' ? (
                              <img
                                className='imgCover'
                                src={this.props.getImg(mAbout[0].name, 1400)}
                              ></img>
                            ) : (
                              <video
                                className='imgCover'
                                autoPlay
                                loop
                                playsInline
                                muted
                                preload='auto'
                              >
                                <source src={mBlog[0].url}></source>
                              </video>
                            )}
                          </Fragment>
                        </div>
                      </div>
                    </div>
                    <div className='pbSm relative consoleTitle plSm prSm'>
                      <p className='onNorm tFooter tUpper tWhite eNone'>
                        {this.props.typeOn(`${this.state.thoughtsDate}`, 5.75)}
                      </p>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            ''
          )} */}
        </div>
      </>
    )
  }
}

export default Hub
